import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import Headline from 'components/Headline/styles';
import ImgFlyers from 'assets/img-flyers.png';
import {DashboardHeaderActions} from 'routes/App/BookADriver/List/styles';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import {ButtonPrimary} from 'components/buttons';
import {
  FlyersContainer,
  FlyersContainerImage,
  FlyersContainerText,
  FlyersSubtitle
} from 'routes/App/Flyers/styles';
import {TOGGLE_REQUEST_MODAL} from 'store/flyers/action';
import RequestFlyersModal from 'routes/App/Flyers/Modals/requestFlyersModal';

const Flyers = () => {
  const dispatch = useDispatch();

  const {loading, isOpenRequestModal} = useSelector(
    (state) => state.flyersReducer
  );

  return (
    <DashboardContent>
      <DashboardHeader>
        <Headline>Flyers</Headline>
        <DashboardHeaderActions>
          <ButtonPrimary
            className={cn('ButtonInsightsPerformanceSettings', {
              disabled: loading
            })}
            onClick={() => dispatch(TOGGLE_REQUEST_MODAL(true))}
          >
            <span>Request Flyers</span>
          </ButtonPrimary>
        </DashboardHeaderActions>
      </DashboardHeader>
      <FlyersSubtitle>
        Help Convert 3rd party Orders into Direct Online Sales with Specially
        Designed Flyers!
      </FlyersSubtitle>
      <FlyersContainer>
        <FlyersContainerText>
          <div className='questionText'>1. What?</div>
          <div>
            Let customers know you are doing deliveries and there’s a better,
            cheaper way for them to be ordering by inserting a flyer in every
            3rd party delivery bag.
          </div>
          <div className='questionText'>2. How?</div>
          <div>
            Pop the flyers in bags for 3rd party delivery or pick up, or just
            hand them out to your walk-in customers. Keep them by the cash till
            for easy access.
          </div>
          <div className='questionText'>3. Why?</div>
          <div>
            Your loyal customers are always looking to support you. So let them
            know that this is the way and its cheaper and better for them. For
            more effect, scribble a note on the flyer and make it personal.
          </div>
        </FlyersContainerText>
        <FlyersContainerImage src={ImgFlyers} alt='' />
      </FlyersContainer>
      {isOpenRequestModal && (
        <RequestFlyersModal
          openModal={isOpenRequestModal}
          closeModalAction={TOGGLE_REQUEST_MODAL}
        />
      )}
    </DashboardContent>
  );
};

export default Flyers;
