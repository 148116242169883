import React, {useEffect, useState} from 'react';
import {Redirect, Route, useHistory, useLocation} from 'react-router-dom';
import {useSelector} from 'react-redux';
import Queue from 'routes/App/Managers/menuQueue/MenuQueue';
import Merchants from 'routes/App/merchants';
import Sidebar from 'routes/App/Managers/components/Sidebar';
import Users from 'routes/App/users';
import FeatureManagement from 'routes/App/Managers/FeatureManagement';
import RealTimeBoard from 'routes/App/Managers/realTimeBoard';
import Navbar from 'routes/App/Managers/Header/Navbar';
import RefundsCalculator from 'routes/App/Managers/RefundsCalculator';
import PhoneOrdering from 'routes/App/Managers/PhoneOrdering';
import InsightsControl from 'routes/App/Managers/InsightsControl';
import RealTimeBoardV2 from 'routes/App/Managers/realTimeBoardV2';
import FindDelivery from 'routes/App/Managers/FindDelivery';
import SudoUsers from 'routes/App/Managers/SudoUsers';
import NoPermission from 'routes/App/Managers/NoPermission';
import Loading from 'components/shared/Utils/Loading';

const ROUTE_PERMISSIONS_MAP = {
  '/managers/merchants/new': 'merchants',
  '/managers/merchants/active': 'merchants',
  '/managers/menu-queue': 'menu_queue',
  '/managers/users': 'users',
  '/managers/permission-management': 'user_management',
  '/managers/feature-management': 'feature_management',
  '/managers/real-time-board': 'real_time_board',
  '/managers/real-time-board-v2': 'real_time_board_v2',
  '/managers/find-delivery': 'find_delivery',
  '/managers/insights-control': 'insights_control',
  '/managers/refunds-calculator': 'refunds_calculator',
  '/managers/phone-ordering': 'phone_ordering'
};

const ROUTE_COMPONENTS = {
  '/managers/merchants/new': Merchants,
  '/managers/merchants/active': Merchants,
  '/managers/menu-queue': Queue,
  '/managers/users': Users,
  '/managers/permission-management': SudoUsers,
  '/managers/feature-management': FeatureManagement,
  '/managers/real-time-board': RealTimeBoard,
  '/managers/real-time-board-v2': RealTimeBoardV2,
  '/managers/find-delivery': FindDelivery,
  '/managers/insights-control': InsightsControl,
  '/managers/refunds-calculator': RefundsCalculator,
  '/managers/phone-ordering': PhoneOrdering,
  '/managers/no-permission': NoPermission
};
const Managers = () => {
  const [showSidebar, setShowSidebar] = useState(false);
  const location = useLocation();
  const history = useHistory();
  const {AllowedFeatures} = useSelector((state) => state.user.userData);
  const [availableRoutes, setAvailableRoutes] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const filterRoutes = (permissions) => {
    const filteredRoutes = Object.keys(ROUTE_COMPONENTS).filter((route) =>
      permissions.includes(ROUTE_PERMISSIONS_MAP[route])
    );
    return filteredRoutes.length > 0
      ? filteredRoutes
      : ['/managers/no-permission'];
  };

  useEffect(() => {
    if (AllowedFeatures && AllowedFeatures.length > 0) {
      const routes = filterRoutes(AllowedFeatures);
      setAvailableRoutes(routes);
      setIsLoading(false);
    } else {
      setAvailableRoutes(['/managers/no-permission']);
      setIsLoading(false);
    }
  }, [AllowedFeatures]);

  const normalizedPath = location.pathname.replace(/\/$/, '');
  const isPathAvailable = availableRoutes.includes(normalizedPath);

  useEffect(() => {
    if (!isLoading) {
      if (!isPathAvailable) {
        history.replace(availableRoutes[0]);
      }
    }
  }, [isLoading, isPathAvailable, availableRoutes, history]);

  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  }

  return (
    <>
      <Navbar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />
      <Sidebar showSidebar={showSidebar} setShowSidebar={setShowSidebar} />

      {availableRoutes.map((route) => {
        const Component = ROUTE_COMPONENTS[route];
        return <Route exact key={route} path={route} component={Component} />;
      })}

      {availableRoutes.length > 0 && !isPathAvailable && (
        <Redirect to={availableRoutes[0]} />
      )}
    </>
  );
};

export default Managers;
