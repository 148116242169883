import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {Dialog} from '@material-ui/core';
import cn from 'classnames';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import DialogBody from 'components/DialogBody/styles';
import {FlexContainer, FlexItem} from 'components/layout';
import AutocompleteField from 'components/AutocompleteField/AutocompleteField';
import {GET_MERCHANTS} from 'store/global/connectingStream/action';
import debounce from 'helpers/functionUtils/debouce';

import {
  ConnectingStreamList,
  ConnectingStreamListItem,
  ConnectingStreamText
} from 'routes/App/ConnectingStream/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import DialogActions from 'components/DialogActions/styles';
import {removeSessionStorageItem} from 'helpers/sessionStorage';

const ConnectStreamModal = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const urlParams = new URLSearchParams(location.search);
  const streamState = urlParams.get('state');
  const {userData} = useSelector((state) => state.user);
  const [merchant, setMerchant] = useState(null);
  const [searchValue, setSearchValue] = useState('');

  const {merchants} = useSelector((state) => state.connectingStreamReducer);

  const debouncedDispatch = debounce((value) => {
    dispatch(GET_MERCHANTS(value));
  }, 500);

  const redirectToUrl = (url) => {
    removeSessionStorageItem('streamUrl');

    window.location.href = url;
  };

  const generateUrl = (path, params) => {
    const searchParams = new URLSearchParams(params);
    return new URL(
      `${process.env.REACT_APP_STREAM_ADAPTER_SERVICE}/api/stream-adapter/dsp/v1/authorize/${path}/redirect?${searchParams}`
    );
  };

  const positiveSendData = () => {
    return () => {
      const url = generateUrl(
        userData?.role === 'sudo' ? 'sudo-user' : 'user',
        {
          state: encodeURIComponent(streamState),
          token: encodeURIComponent(localStorage.getItem('secretKey')),
          ...(userData?.role === 'sudo' && {merchant_id: merchant.id})
        }
      );
      redirectToUrl(`${url.origin}${url.pathname}${url.search}`);
    };
  };

  const negativeSendData = () => {
    return () => {
      const url = generateUrl('failure', {
        state: encodeURIComponent(streamState),
        error: 'access_denied'
      });

      redirectToUrl(`${url.origin}${url.pathname}${url.search}`);
    };
  };

  return (
    <Dialog open scroll='body'>
      <DialogContainer
        className={
          userData?.role === 'sudo'
            ? 'DialogMd'
            : 'DialogSm ConnectingStreamPopup'
        }
      >
        <DialogTitle className='marginBottomLg'>
          Connect merchant to Stream{userData?.role !== 'sudo' ? ': ' : ' '}
          {userData?.role !== 'sudo' && (
            <div
              title={userData?.MerchantOriginName}
              className='ConnectingStreamLimitedText'
            >
              {userData?.MerchantOriginName}
            </div>
          )}
        </DialogTitle>
        <DialogBody>
          {userData?.role === 'sudo' && (
            <>
              <ConnectingStreamText>
                Select the merchant which has to be connected
              </ConnectingStreamText>
              <FlexContainer
                justifyContent='space-between'
                className='align-center'
              >
                <FlexItem flex={1} className='marginBottomLg'>
                  <AutocompleteField
                    className='w100'
                    value={merchant}
                    options={merchants}
                    name='merchant'
                    optionLabelName='name'
                    onChange={(e, value) => {
                      setMerchant(value);
                      setSearchValue('');
                    }}
                    inputOnChange={(e) => {
                      const inputValue = e.target.value;
                      setSearchValue(inputValue);
                      if (e.target.value?.trim()?.length > 0) {
                        debouncedDispatch(inputValue);
                      }
                    }}
                    hideDropdownOnEmptyInput
                    searchValue={searchValue}
                  />
                </FlexItem>
              </FlexContainer>
            </>
          )}
          <ConnectingStreamText
            className={cn('', {
              marginLeft40: userData?.role !== 'sudo'
            })}
          >
            Stream needs access to your account. Please grant the necessary
            permissions:
          </ConnectingStreamText>
          <ConnectingStreamList
            className={cn('', {
              marginLeft70: userData?.role !== 'sudo'
            })}
          >
            <ConnectingStreamListItem>
              View your merchant profile information
            </ConnectingStreamListItem>
            <ConnectingStreamListItem>
              Update your Sauce location settings
            </ConnectingStreamListItem>
            <ConnectingStreamListItem>
              Change and update your menu data
            </ConnectingStreamListItem>
            <ConnectingStreamListItem>
              View your Sauce orders
            </ConnectingStreamListItem>
            <ConnectingStreamListItem>
              Update your Sauce orders
            </ConnectingStreamListItem>
          </ConnectingStreamList>
          <DialogActions className='justifyCenter'>
            <DialogAction
              className={cn('ButtonConnectingStreamPR', {
                w30: userData?.role === 'sudo',
                w35: userData?.role !== 'sudo'
              })}
            >
              <ButtonOutline onClick={negativeSendData()}>
                <span>Deny</span>
              </ButtonOutline>
            </DialogAction>
            <DialogAction
              className={cn('ButtonConnectingStreamPL', {
                w30: userData?.role === 'sudo',
                w35: userData?.role !== 'sudo'
              })}
            >
              <ButtonPrimary onClick={positiveSendData()}>
                <span>Allow</span>
              </ButtonPrimary>
            </DialogAction>
          </DialogActions>
        </DialogBody>
      </DialogContainer>
    </Dialog>
  );
};

export default ConnectStreamModal;
