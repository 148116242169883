import styled from 'styled-components';
import IconSort from '../../assets/icon-sort.svg';
import IconFutureOrder from '../../assets/icon-future-order.svg';
import IconRefundOrder from '../../assets/icon-refund-order.svg';

export const TableSort = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 1;
  cursor: pointer;
  color: #232d3a;
  display: inline-block;
  padding-right: 20px;
  position: relative;
  font-family: 'Poppins';
  @media (max-width: 1700px) {
    font-size: 14px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 11px;
    height: 15px;
    background-image: url('${(props) => props.icon || IconSort}');
    background-size: contain;
    background-repeat: no-repeat;
    @media (max-width: 1440px) {
      width: 10px;
      height: 14px;
    }
  }
`;

export const TableWrapper = styled.div`
  .MuiTableCell-head {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    color: #232d3a;
    position: relative;
    font-family: 'Poppins';
    line-height: 1;

    &.headTableCell {
      padding-top: 30px;
      padding-bottom: 15px;
      @media (max-width: 1024px) {
        padding-top: 25px;
      }
      @media (max-width: 767px) {
        padding-top: 20px;
      }
    }

    @media (max-width: 1700px) {
      font-size: 14px;
    }
  }

  .MuiTable-root {
    border-collapse: separate;

    .MuiTableRow-root td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

    .MuiTableRow-root td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    tr {
      &:first-child {
        th {
          &:nth-child(1) {
            width: 16%;
            @media (max-width: 1000px) {
              width: 15%;
            }
          }

          &:nth-child(2) {
            width: 28%;
          }

          &:nth-child(3) {
            width: 15%;
            @media (max-width: 1000px) {
              width: 15%;
            }
          }

          &:nth-child(4) {
            width: 15%;
            @media (max-width: 1000px) {
              width: 17%;
            }
          }

          &:nth-child(5) {
            width: 15%;
          }

          &:nth-child(6) {
            width: 15%;
          }
        }
      }
    }
  }

  .futureTableRow {
    td {
      border-top: 3px solid #17c981;
      border-bottom: 3px solid #17c981;
      &:first-child {
        position: relative;
        border-left: 3px solid #17c981;
        div {
          position: inherit;
        }
      }
      &:last-child {
        border-right: 3px solid #17c981;
      }
    }

    .IconFutureOrder {
      position: absolute;
      top: -12px;
      left: -16px;
      width: 128px;
      height: 44px;
      background-image: url(${IconFutureOrder});
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 2;
      pointer-events: none;
    }
  }
  .refundTableRow {
    td {
      border-top: 3px solid #4671db;
      border-bottom: 3px solid #4671db;
      &:first-child {
        position: relative;
        border-left: 3px solid #4671db;
        div {
          position: inherit;
        }
      }
      &:last-child {
        border-right: 3px solid #4671db;
      }
    }

    .IconRefundOrder {
      position: absolute;
      top: -12px;
      left: -16px;
      width: 128px;
      height: 44px;
      background-image: url(${IconRefundOrder});
      background-repeat: no-repeat;
      background-size: contain;
      z-index: 2;
      pointer-events: none;
    }
  }

  .rdbGreyRow {
    td {
      border-top: 2px solid #e3e3e3;
      border-bottom: 2px solid #e3e3e3;
      background-color: #e3e3e3;
      &:first-child {
        border-left: 2px solid #e3e3e3;
      }
      &:last-child {
        border-right: 2px solid #e3e3e3;
      }
    }
  }

  .rdbRedRow {
    td {
      border-top: 2px solid #ff2d38;
      border-bottom: 2px solid #ff2d38;
      &:first-child {
        position: relative;
        border-left: 2px solid #ff2d38;
        div {
          //position: inherit;
        }
      }
      &:last-child {
        border-right: 2px solid #ff2d38;
      }
    }
    .IconPin {
      svg {
        path {
          fill: #ff2d38;
        }
      }
    }
  }

  .rdbOrangeRow {
    td {
      border-top: 2px solid #ff8412;
      border-bottom: 2px solid #ff8412;
      &:first-child {
        position: relative;
        border-left: 2px solid #ff8412;
        div {
          //position: inherit;
        }
      }
      &:last-child {
        border-right: 2px solid #ff8412;
      }
    }
    .IconPin {
      svg {
        path {
          fill: #ff8412;
        }
      }
    }
  }

  .rdbGreenRow {
    td {
      border-top: 2px solid #17c981;
      border-bottom: 2px solid #17c981;
      &:first-child {
        position: relative;
        border-left: 2px solid #17c981;
        div {
          //position: inherit;
        }
      }
      &:last-child {
        border-right: 2px solid #17c981;
      }
    }
    .IconPin {
      svg {
        path {
          fill: #17c981;
        }
      }
    }
  }

  tr.rdbLastOfTypeRow + div.TableBottomIndent {
    margin-bottom: 16px !important;
  }

  @keyframes blink {
    50% {
      opacity: 0;
    }
  }

  .blink1times {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: 1;
  }

  .blink3times {
    animation-name: blink;
    animation-duration: 1s;
    animation-iteration-count: 3;
  }

  .tableRow {
    .ordersNotFound {
      font-weight: bold;
      text-align: center;
    }
    &.highlighted {
      @keyframes glowing {
        0% {
          background-color: #ffffff;
          box-shadow: 0 0 5px #95ff77;
        }
        50% {
          background-color: #ffffff;
          box-shadow: 0 0 20px #daffd0;
        }
        100% {
          background-color: #ffffff;
          box-shadow: 0 0 5px #95ff77;
        }
      }
      animation: glowing 1300ms infinite;
    }

    vertical-align: middle;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.2),
      10px 10px 20px rgba(108, 49, 151, 0.06),
      30px 30px 30px rgba(108, 49, 151, 0.04);
  }

  .MuiTableCell-root {
    border-bottom: 0;
    position: relative;
    padding: 15px 6px;
    @media (max-width: 1500px) {
      padding: 15px 4px;
    }
    @media (max-width: 1024px) {
      padding: 5px;
    }

    &:first-child {
      padding-left: 28px;
    }

    &:last-child {
      padding-right: 12px;
      @media (max-width: 1000px) {
        padding-right: 15px;
      }
    }
  }

  .TableBottomIndent {
    margin-bottom: 16px;
  }
`;

export const TableText = styled.p`
  font-family: 'Poppins';
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 1.4;
  color: #232d3a;
  @media (max-width: 1700px) {
    font-size: 14px;
  }
  @media (max-width: 1024px) {
    font-size: 13px;
  }
  @media (max-width: 767px) {
    font-size: 12px;
  }
`;
