import React from 'react';

const IconSidebarFlyers = () => (
  <svg
    width='16'
    height='16'
    viewBox='0 0 16 16'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g clipPath='url(#clip0_27292_15861)'>
      <path
        d='M4.56016 1.71447C4.56016 1.51794 4.68278 1.34225 4.86725 1.27447L8.33628 0H0.820312C0.561844 0 0.351562 0.210281 0.351562 0.46875V14.0758C0.351562 14.3342 0.561844 14.5445 0.820312 14.5445H4.56016V1.71447Z'
        fill='#929CB9'
      />
      <path
        d='M15.1805 2.18311H5.49805V15.5311C5.49805 15.7896 5.70833 15.9999 5.9668 15.9999H15.1805C15.439 15.9999 15.6492 15.7896 15.6492 15.5311V2.65186C15.6493 2.39339 15.439 2.18311 15.1805 2.18311ZM7.19402 4.40567H9.51936C9.77823 4.40567 9.98811 4.61554 9.98811 4.87442C9.98811 5.13329 9.77823 5.34317 9.51936 5.34317H7.19402C6.93514 5.34317 6.72527 5.13329 6.72527 4.87442C6.72527 4.61554 6.93514 4.40567 7.19402 4.40567ZM7.19402 6.51423H9.51936C9.77823 6.51423 9.98811 6.72411 9.98811 6.98298C9.98811 7.24186 9.77823 7.45173 9.51936 7.45173H7.19402C6.93514 7.45173 6.72527 7.24186 6.72527 6.98298C6.72527 6.72411 6.93514 6.51423 7.19402 6.51423ZM13.9533 13.7773H7.19402C6.93514 13.7773 6.72527 13.5674 6.72527 13.3086C6.72527 13.0497 6.93514 12.8398 7.19402 12.8398H13.9533C14.2121 12.8398 14.422 13.0497 14.422 13.3086C14.422 13.5674 14.2122 13.7773 13.9533 13.7773ZM13.9533 11.6688H7.19402C6.93514 11.6688 6.72527 11.4589 6.72527 11.2C6.72527 10.9412 6.93514 10.7313 7.19402 10.7313H13.9533C14.2121 10.7313 14.422 10.9412 14.422 11.2C14.422 11.4589 14.2122 11.6688 13.9533 11.6688ZM13.9533 9.56026H7.19402C6.93514 9.56026 6.72527 9.35039 6.72527 9.09151C6.72527 8.83264 6.93514 8.62276 7.19402 8.62276H13.9533C14.2121 8.62276 14.422 8.83264 14.422 9.09151C14.422 9.35039 14.2122 9.56026 13.9533 9.56026ZM14.422 6.98298C14.422 7.24186 14.2121 7.45173 13.9533 7.45173H11.6279C11.369 7.45173 11.1592 7.24186 11.1592 6.98298V4.87442C11.1592 4.61554 11.369 4.40567 11.6279 4.40567H13.9533C14.2121 4.40567 14.422 4.61554 14.422 4.87442V6.98298Z'
        fill='#929CB9'
      />
      <path d='M11.0549 0L7.66406 1.24572H11.0549V0Z' fill='#929CB9' />
      <path
        d='M12.0967 5.34326H13.4845V6.51432H12.0967V5.34326Z'
        fill='#929CB9'
      />
    </g>
    <defs>
      <clipPath id='clip0_27292_15861'>
        <rect width='16' height='16' fill='white' />
      </clipPath>
    </defs>
  </svg>
);

export default IconSidebarFlyers;
