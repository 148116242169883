import React, {useCallback, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroll-component';
import {useDispatch, useSelector} from 'react-redux';
import {IconButton} from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import {createPortal} from 'react-dom';
import cn from 'classnames';
import {debounce} from 'lodash';
import ButtonPrimary from '../../../../components/buttons/ButtonPrimary/styles';
import {
  DashboardField,
  DashboardFieldInner,
  DashboardFieldLabel,
  DashboardFields,
  FieldLabel
} from '../../dashboard/styles';
import {NewLineBlock, OrderEta} from './styles';
import TableComponent from '../../../../components/TableComponent';
import {
  CHANGE_STATUS_HISTORY,
  CLEAR_ORDERS,
  GET_ORDER,
  GET_ORDERS,
  SET_FILTER,
  SET_SEARCH,
  SET_SORTING
} from '../../../../store/orders/orderList/action';
import IconPrint from '../../../../assets/IconPrint';
import {
  ORDER,
  ORDER_CHANNELS,
  ORDER_MISC_MANY,
  ORDER_PAYMENT_METHODS,
  ORDER_STATUS,
  ORDER_TYPE,
  ORDER_TYPES,
  ORDER_TYPES_ALL
} from '../../../../helpers/constants/orders';
import StatusButton from './components/StatusButton';
import {FlexContainer, FlexItem} from '../../../../components/layout';
import {groupDigital} from '../../../../helpers/functionUtils/groupByDigital';
import SelectField from '../../../../components/SelectField';
import DatepickerModule from '../../../../components/DatepickerModule';
import {
  RUN_GET_ACTIVE_ORDERS_JOB,
  STOP_GET_ACTIVE_ORDERS_JOB
} from '../../../../store/global/orders/action';
import {phoneFormatForView} from '../../../../helpers/functionUtils/helpFunctions';
import ButtonScrollTop from '../../../../components/buttons/ButtonScrollTop';
import IconPickUpDefault from '../../../../assets/icon-pickup-default.svg';
import IconCurbsidePickup from '../../../../assets/icon-curbside-pickup.svg';
import UberEats from '../../../../assets/uber-eats.svg';
import SauceDelivery from '../../../../assets/sauce-delivery.png';
import IconDinein from '../../../../assets/icon-dinein.svg';
import {ButtonOutline} from '../../../../components/buttons';
import {
  OrdersTableWrapper,
  SavingsIconButton
} from '../components/OrdersTable/styles';
import {FEATURE_RESTRICTION_IDS} from '../../../../helpers/constants/restrictions';
import RestrictedArea from '../../../../components/layout/RestrictedArea';
import {checkIsRefundOrder} from '../../../../helpers/functionUtils/ordersUtils';
import IconRestaurantDelivery from '../../../../assets/icon-restaurant-delivery.svg';
import IconSauceDelivery from '../../../../assets/icon-sauce-delivery.svg';
import {OPEN_CANCELLATION_CONFIRMATION_MODAL} from '../../../../store/global/modals/action';
import {CHANGE_STATUS} from '../../../../store/orders/orderDetails/action';
import SearchField from '../../../../components/fields/SearchField';

const getFieldsConfig = (
  onStatusChange,
  onOpenDetailsClick,
  onPrintClick,
  changeStatusLoading,
  showDeliverWithUsButton,
  showSavingPigIcon,
  onDeliverWithUsClick
) => [
  {
    title: 'ETA',
    onCellClick: onOpenDetailsClick,
    sortable: true,
    fieldName: 'eta_time_for_merchant',
    renderer: (data) => (
      <OrderEta>
        {checkIsRefundOrder(data) && <span className='IconRefundOrder' />}
        {moment(data[ORDER.DATE]).utcOffset(0).format('MMMM D, h:mm A')}
      </OrderEta>
    )
  },
  {
    title: 'Customer',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <FlexContainer className='TableCenter'>
        <NewLineBlock className='wordWrap'>
          {data[ORDER.USER_NAME]}
        </NewLineBlock>
        <NewLineBlock>
          <a
            href={`tel:${data[ORDER.USER_PHONE]}`}
            alt='customer-phone-number'
            onClick={(e) => e.stopPropagation()}
          >
            {phoneFormatForView(data[ORDER.USER_PHONE])}
          </a>
        </NewLineBlock>
      </FlexContainer>
    )
  },
  // {
  //   title: 'Order channel',
  //   onCellClick: onOpenDetailsClick,
  //   renderer: (data) => (
  //     <FlexContainer className='align-center orderProvider'>
  //       <FlexItem className='orderIconContainer'>
  //         {data[ORDER.PLATFORM]?.toLowerCase().includes('uber') ? (
  //           <img src={UberEats} alt='o' />
  //         ) : (
  //           <img src={SauceDelivery} alt='logo' />
  //         )}
  //       </FlexItem>
  //       <FlexItem>
  //         {data[ORDER.PLATFORM]?.toLowerCase().includes('uber')
  //           ? 'UberEats'
  //           : 'Sauce'}
  //       </FlexItem>
  //     </FlexContainer>
  //   )
  // },
  {
    title: 'Order type',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => {
      return (
        <FlexContainer className='align-center orderProvider'>
          <FlexItem flex={1} className='orderIconContainer'>
            {(() => {
              if (data?.isCurbside) {
                return <img src={IconCurbsidePickup} alt='logo' />;
              }
              switch (data[ORDER.ORDER_TYPE]) {
                case 'DineIn':
                  return <img src={IconDinein} alt='logo' />;
                case 'SauceDispatch':
                  return <img src={IconSauceDelivery} alt='logo' />;
                case 'UberEatsDelivery':
                  return <img src={UberEats} alt='logo' />;
                case 'UberPickUp':
                  return <img src={UberEats} alt='logo' />;
                case 'Delivery':
                  return <img src={IconRestaurantDelivery} alt='logo' />;
                default:
                  return <img src={IconPickUpDefault} alt='logo' />;
              }
            })()}
          </FlexItem>
          <FlexItem className='marginRightSm'>
            <>
              {data?.isCurbside
                ? ORDER_TYPES[ORDER_TYPE.CURBSIDE_PICKUP]
                : ORDER_TYPES[data[ORDER.ORDER_TYPE]]}
            </>
          </FlexItem>
          <RestrictedArea
            restrictions={[]}
            features={[FEATURE_RESTRICTION_IDS.UBER_EATS]}
            extraAccessLogic={() =>
              data[ORDER.DELIVERY_PROVIDER] === 'Uber Eats' &&
              showSavingPigIcon &&
              data[ORDER.ORDER_TYPE] === 'SauceDispatch'
            }
          >
            <FlexItem>
              <SavingsIconButton>
                <div className='DropInfo DropInfoCenter'>
                  You save money on every UberEats order that Sauce delivers.
                </div>
              </SavingsIconButton>
            </FlexItem>
          </RestrictedArea>
        </FlexContainer>
      );
    }
  },
  {
    onCellClick: () => {},
    renderer(data = {}) {
      if (
        data[ORDER.DELIVERY_PROVIDER] === 'Uber Eats' &&
        showDeliverWithUsButton
      ) {
        return (
          <RestrictedArea
            restrictions={[]}
            features={[FEATURE_RESTRICTION_IDS.UBER_EATS]}
          >
            <FlexItem>
              <ButtonOutline
                className='ButtonDelivery'
                onClick={onDeliverWithUsClick}
              >
                Deliver with us
              </ButtonOutline>
            </FlexItem>
          </RestrictedArea>
        );
      }
      return false;
    }
  },
  {
    title: 'Amount',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <div>{`$${groupDigital(Number(data[ORDER.AMOUNT]).toFixed(2))} ${
        data[ORDER.PAYMENT_TYPE] || ''
      }`}</div>
    )
  },
  {
    title: 'Order Status',
    sortable: true,
    fieldName: 'OrderStatus',
    renderer: (data) => {
      return (
        <div
          style={
            changeStatusLoading === data[ORDER.ID] ||
            data[ORDER.ORDER_STATUS] === ORDER_STATUS.CANCELLED
              ? {opacity: '0.5', pointerEvents: 'none'}
              : null
          }
        >
          <StatusButton
            order={data}
            onStatusChange={(status) => {
              onStatusChange(
                data[ORDER.ID],
                status,
                data[ORDER.ORDER_STATUS],
                data[ORDER.DELIVERY_PROVIDER]
              );
            }}
          />
        </div>
      );
    }
  },
  {
    renderer: (item) => (
      <FlexContainer className='justify-end'>
        <IconButton onClick={() => onPrintClick(item.id)}>
          <IconPrint />
        </IconButton>
      </FlexContainer>
    )
  }
];

// const getFilterCheckbox = (filter, name, value, onChange) => {
//   return (
//     <DialogCheckbox
//       name={name}
//       checked={!filter || filter.includes(value)}
//       // disabled={!filter}
//       onChange={({target: {checked}}) => {
//         onChange({
//           orderStatus: checked
//             ? [...filter, value]
//             : filter.filter((item) => item !== value)
//         });
//       }}
//     />
//   );
// };

const OrdersHistory = ({parentRef, print}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const locationName = useSelector((state) => state.user.locationName);
  const {
    orders,
    loading,
    filter: {
      startDate,
      endDate,
      orderChannels,
      orderTypes,
      datePickerSelectIndex,
      orderMiscMany,
      orderPaymentsMethods
    },
    hasNextPage,
    sorting: {sort, sortBy},
    changeStatusLoading,
    search
  } = useSelector((state) => state.ordersHistoryReducer);

  const UEConnected = useSelector(
    (state) => state.uberEatsReducer.uberEatsData?.isActivated
  );
  const BYOCconnected = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.byoc?.isActivated
  );
  const BYOCconfigured = useSelector(
    (state) =>
      state.uberEatsReducer.uberEatsData?.activationSettings?.isByocEnabled
  );
  const getOrders = (isCustomerSearch = false) => {
    dispatch(GET_ORDERS({isCustomerSearch}));
  };
  const {Timezone, AllowedServiceFeatures} = useSelector(
    (state) => state.user.activeLocation
  );

  useEffect(() => {
    dispatch(CLEAR_ORDERS());
    dispatch(SET_SEARCH(''));

    if (!startDate && datePickerSelectIndex) {
      dispatch(
        SET_FILTER({
          startDate: moment()
            .utcOffset(Timezone, false)
            .utc()
            .startOf('isoWeek')
            .format(),
          endDate: moment()
            .utcOffset(Timezone, false)
            .utc()
            .endOf('isoWeek')
            .format()
        })
      );
    }

    getOrders();

    return () => dispatch(CLEAR_ORDERS());
  }, [locationName]);

  const onFilterChange = (filter) => {
    dispatch(CLEAR_ORDERS());
    dispatch(SET_FILTER(filter));
    getOrders();
  };
  const onSortingChange = (sorting) => {
    dispatch(CLEAR_ORDERS());
    dispatch(SET_SORTING(sorting));
    getOrders();
  };

  const onStatusChange = (id, status, previousStatus, deliveryProvider) => {
    if (status !== previousStatus && !changeStatusLoading) {
      if (status === ORDER_STATUS.CANCELLED) {
        dispatch(
          OPEN_CANCELLATION_CONFIRMATION_MODAL({
            isOpen: true,
            info: {id, status, previousStatus},
            source: 'history',
            deliveryProvider
          })
        );
      } else {
        dispatch(CHANGE_STATUS_HISTORY({id, status, previousStatus}));
        dispatch(STOP_GET_ACTIVE_ORDERS_JOB());
        dispatch(RUN_GET_ACTIVE_ORDERS_JOB());
      }
    }
  };

  const openDetails = (data) => {
    history.push(`/orders/history/${data[ORDER.ID]}/details`, {
      windowPosition: window.scrollY
    });
  };

  const printOrder = (id) => {
    dispatch(GET_ORDER({id, successCb: print}));
  };

  const updateDate = (date) => {
    if (date && date[0] && date[1]) {
      onFilterChange({
        startDate: date[0],
        endDate: date[1]
      });
    } else {
      onFilterChange({
        startDate: null,
        endDate: null
      });
    }
  };

  const onDeliverWithUsClick = () => {
    history.push('/uber-eats');
  };

  const updateSelectedDateIndex = (selectedIndex) => {
    dispatch(SET_FILTER({datePickerSelectIndex: selectedIndex}));
  };

  const debouncedGetOrders = useCallback(
    debounce((isCustomerSearch) => {
      getOrders(isCustomerSearch);
    }, 500),
    []
  );

  const setSearch = (value) => {
    dispatch(SET_SEARCH(value));
    debouncedGetOrders(true);
  };

  const DashboardFilters = ({
    datepickerDate,
    types,
    searchValue,
    setSearchValue
  }) => (
    <>
      <br />
      {AllowedServiceFeatures?.includes('search_orders') && (
        <FlexContainer justifyContent='flex-end'>
          <FlexItem flex='0.3 1 auto'>
            <SearchField
              placeholder='Search orders by customer name'
              className='marginBottomXs'
              value={searchValue}
              onChange={(e) => {
                setSearchValue(e.target.value);
              }}
              onClear={() => setSearchValue('')}
              isClearIconVisible={searchValue?.length > 0}
            />
          </FlexItem>
        </FlexContainer>
      )}
      {/* <DisplayField */}
      {/*  className='marginBottomMd' */}
      {/*  label={<Body1>Now Editing:</Body1>} */}
      {/*  value={<Caption1>{locationName}</Caption1>} */}
      {/* /> */}
      <DashboardFields>
        <DashboardField className='dFlexNoWrapAuto'>
          <DashboardFieldLabel className='OrderFilterLabel'>
            <FieldLabel className='FieldLabelLg'>Filter</FieldLabel>
          </DashboardFieldLabel>
          <DashboardFieldInner className='OrderFilterSelect Say2EatSelect'>
            <FormControl>
              <SelectField
                multiple
                MenuProps={{
                  disableScrollLock: true,
                  anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'left'
                  },
                  transformOrigin: {
                    vertical: 'top',
                    horizontal: 'left'
                  },
                  getContentAnchorEl: null
                }}
                name='Provider'
                value={types}
                onChange={({target: {value}}) => {
                  if (
                    value.length === 12 ||
                    value[value.length - 1] === 'All' ||
                    !value.length
                  ) {
                    onFilterChange({
                      orderTypes: ['All'],
                      orderChannels: ['All'],
                      orderMiscMany: ['All'],
                      orderPaymentsMethods: ['All']
                    });
                    return;
                  }

                  if (value.length > 1 && value.indexOf('All') !== -1) {
                    value.splice(value.indexOf('All'), 1);
                  }
                  const typesItem = value.filter((item) =>
                    Object.values(ORDER_TYPES).includes(item)
                  );
                  const channelsItem = value.filter((item) =>
                    Object.values(ORDER_CHANNELS).includes(item)
                  );
                  const miscItem = value.filter((item) =>
                    Object.values(ORDER_MISC_MANY).includes(item)
                  );
                  const paymentsMethodsItem = value.filter((item) =>
                    Object.values(ORDER_PAYMENT_METHODS).includes(item)
                  );
                  onFilterChange({
                    orderTypes: typesItem,
                    orderChannels: channelsItem,
                    orderMiscMany: miscItem,
                    orderPaymentsMethods: paymentsMethodsItem
                  });
                }}
                menuItemsChannel={Object.values(ORDER_CHANNELS)}
                menuItemsTypes={Object.values(ORDER_TYPES)}
                menuItemsMisc={Object.values(ORDER_MISC_MANY)}
                menuItemsPaymentMethod={Object.values(ORDER_PAYMENT_METHODS)}
                menuItemsAll={Object.values(ORDER_TYPES_ALL)}
              />
            </FormControl>
          </DashboardFieldInner>
        </DashboardField>
        <DashboardField className='wAutoSpace dFlex'>
          <DashboardFieldLabel className='widthAuto'>
            <FieldLabel className='FieldLabelLg'>Time period</FieldLabel>
          </DashboardFieldLabel>
          <DashboardFieldInner className='widthWithoutLabel'>
            <DatepickerModule
              date={datepickerDate}
              updateDate={updateDate}
              defaultSelectIndex={datePickerSelectIndex}
              updateSelectedDateIndex={updateSelectedDateIndex}
              updatedAllPeriods='true'
            />
          </DashboardFieldInner>
        </DashboardField>
        {/* <DialogField className='w70 Say2EatSelect' />{' '} */}
        {/* <DialogField className='w50' /> */}
        {/* <DialogField className='w100'> */}
        {/*  <DialogCheckboxes> */}
        {/*    {getFilterCheckbox( */}
        {/*      orderStatus, */}
        {/*      'New', */}
        {/*      ORDER_STATUS.NEW, */}
        {/*      onFilterChange */}
        {/*    )} */}
        {/*    {getFilterCheckbox( */}
        {/*      orderStatus, */}
        {/*      'Accepted', */}
        {/*      ORDER_STATUS.ACCEPTED, */}
        {/*      onFilterChange */}
        {/*    )} */}
        {/*    {getFilterCheckbox( */}
        {/*      orderStatus, */}
        {/*      'Overdue', */}
        {/*      ORDER_STATUS.OVERDUE, */}
        {/*      onFilterChange */}
        {/*    )} */}
        {/*    {getFilterCheckbox( */}
        {/*      orderStatus, */}
        {/*      'Completed', */}
        {/*      ORDER_STATUS.COMPLETED, */}
        {/*      onFilterChange */}
        {/*    )} */}
        {/*    {getFilterCheckbox( */}
        {/*      orderStatus, */}
        {/*      'Cancelled', */}
        {/*      ORDER_STATUS.CANCELLED, */}
        {/*      onFilterChange */}
        {/*    )} */}
        {/*    <DialogCheckbox */}
        {/*      name='All' */}
        {/*      checked={ */}
        {/*        orderStatus.length === Object.values(ORDER_STATUS).length */}
        {/*      } */}
        {/*      onChange={({target: {checked}}) => { */}
        {/*        onFilterChange({ */}
        {/*          orderStatus: checked ? Object.values(ORDER_STATUS) : [] */}
        {/*        }); */}
        {/*      }} */}
        {/*    /> */}
        {/*  </DialogCheckboxes> */}
        {/* </DialogField> */}
      </DashboardFields>
    </>
  );

  DashboardFilters.propTypes = {
    datepickerDate: PropTypes.array.isRequired,
    types: PropTypes.array.isRequired,
    searchValue: PropTypes.string.isRequired,
    setSearchValue: PropTypes.func.isRequired
  };

  const renderDashboardFilters = useCallback(
    (date, types, searchValue, setSearchValue) => {
      if (parentRef) {
        return createPortal(
          <DashboardFilters
            datepickerDate={date}
            types={types}
            searchValue={searchValue}
            setSearchValue={setSearchValue}
          />,
          parentRef
        );
      }
      return null;
    },
    [parentRef, AllowedServiceFeatures]
  );
  return (
    <>
      {renderDashboardFilters(
        [startDate, endDate],
        [
          ...new Set([
            ...orderTypes,
            ...orderChannels,
            ...orderMiscMany,
            ...orderPaymentsMethods
          ])
        ],
        search,
        setSearch
      )}
      <InfiniteScroll
        dataLength={orders.length}
        next={getOrders}
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          overflow: 'inherit'
        }}
        hasMore={hasNextPage}
      >
        <OrdersTableWrapper className='OrdersTableWrapper'>
          <TableComponent
            fields={getFieldsConfig(
              onStatusChange,
              openDetails,
              printOrder,
              changeStatusLoading,
              UEConnected && !BYOCconfigured,
              BYOCconnected,
              onDeliverWithUsClick
            )}
            data={orders}
            loading={loading}
            rowKeyPath={ORDER.ID}
            onSort={onSortingChange}
            orderBy={sortBy}
            orderDirection={sort}
            isOrdersTable
          />
        </OrdersTableWrapper>

        <ButtonScrollTop />
      </InfiniteScroll>
      {hasNextPage && (
        <ButtonPrimary
          onClick={getOrders}
          type='button'
          className={`${loading && 'disabled'} loadMore`}
        >
          <span>Load More</span>
        </ButtonPrimary>
      )}
    </>
  );
};

OrdersHistory.propTypes = {
  parentRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({current: PropTypes.any})
  ]).isRequired,
  print: PropTypes.func.isRequired
};

export default OrdersHistory;
