import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import {DashboardContent} from 'components/DashboardContent/styles';
import {DashboardHeader} from 'components/DashboardHeader/styles';
import Headline from 'components/Headline/styles';
import {IconInsightsGrowth} from 'assets/IconInsightsGrowth';
import {
  InsightsGrowsMetricContainer,
  InsightsGrowsMetric
} from 'routes/App/Insights/Growth/styles';
import {ButtonInfo} from 'components/buttons';
import {INSIGHTS_GROWTH_FEATURES_INFO} from 'helpers/constants/Insights';
import {
  INSIGHTS_GROWTH_CLEAR_INFO,
  INSIGHTS_GROWTH_GET_INFO,
  INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL
} from 'store/insights/growth/action';
import ContactUsModal from 'routes/App/Insights/Growth/Modals/ContactUsModal';
import Loading from 'components/shared/Utils/Loading';
import {EmailMarketingSpinner} from 'routes/App/emailMarketing/components/styles';
import InsightsGrowthContainer from 'routes/App/Insights/Growth/components/InsightsGrowthContainer';

const InsightsGrowth = () => {
  const dispatch = useDispatch();
  const {locationId} = useSelector((state) => state.user);
  const [rotateValue, setRotateValue] = useState(0);
  const {features, modalInfo, loading} = useSelector(
    (state) => state.insightsGrowthReducer
  );
  const {AllowedServiceFeatures} = useSelector(
    (state) => state.user.activeLocation
  );

  useEffect(() => {
    dispatch(INSIGHTS_GROWTH_GET_INFO());
    return () => {
      dispatch(INSIGHTS_GROWTH_CLEAR_INFO());
    };
  }, [locationId]);

  useEffect(() => {
    if (features.length) {
      // setTimeout for animation
      setTimeout(() => {
        const totalWeightEnabled = features
          .filter((item) => item.status === 'Enabled')
          .reduce((sum, item) => sum + item.weight, 0);
        // 180 is half a circle and 100 is the max weight
        const calculateRotateValue =
          ((totalWeightEnabled > 100 ? 100 : totalWeightEnabled) / 100) * 180;
        setRotateValue(calculateRotateValue);
      }, 100);
    }
  }, [loading]);

  return (
    <>
      <DashboardContent
        className={cn({
          centerSpinner: loading
        })}
      >
        <DashboardHeader>
          <Headline className='mTop5'>Insights (Growth)</Headline>
        </DashboardHeader>
        <>
          {loading ? (
            <EmailMarketingSpinner>
              <Loading />
            </EmailMarketingSpinner>
          ) : (
            <>
              <InsightsGrowsMetricContainer>
                <InsightsGrowsMetric rotateValue={rotateValue}>
                  <div className='growthMetricTitle'>
                    <span>How Growth Optimized are you?</span>
                    <ButtonInfo className='InsightsGrowsMetric ButtonInfoDrop'>
                      <div className='DropInfo pointer-events-none DropInfoLeftMob'>
                        This view visualizes your efforts to boost your direct
                        online sales and increase your profits. <br />
                        <br /> The modules below are based on an analysis of
                        customer ordering over years of activity. The more
                        modules you add successfully, the higher you’ll score on
                        the Growth Optimizer and the more direct revenue you
                        will generate for your restaurant.
                      </div>
                    </ButtonInfo>
                  </div>
                  <IconInsightsGrowth />
                </InsightsGrowsMetric>
              </InsightsGrowsMetricContainer>
              <InsightsGrowthContainer
                features={features}
                section='DIGITAL_OPTIMIZATION'
                allowedServiceFeatures={AllowedServiceFeatures}
              />
              <InsightsGrowthContainer
                features={features}
                section='MENU_OPTIMIZATION'
                allowedServiceFeatures={AllowedServiceFeatures}
              />
              <InsightsGrowthContainer
                features={features}
                section='PRICING_AND_OFFERS'
                allowedServiceFeatures={AllowedServiceFeatures}
              />
              <InsightsGrowthContainer
                features={features}
                section='MARKETING'
                allowedServiceFeatures={AllowedServiceFeatures}
              />
            </>
          )}
        </>
      </DashboardContent>
      {modalInfo?.isOpen && (
        <ContactUsModal
          openModal={modalInfo.isOpen}
          feature={modalInfo.feature}
          featuresConst={INSIGHTS_GROWTH_FEATURES_INFO}
          closeModalAction={INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL}
          insightsType='Growth'
        />
      )}
    </>
  );
};

export default InsightsGrowth;
