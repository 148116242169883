import {request} from '../utils/request';
import {PUT, POST} from '../constants/API_CONSTANTS';
import {
  API_UPDATE_LOCATION_SERVICE_FEATURES,
  UPDATE_SERVICE_FEATURE,
  UPDATE_SERVICE_FEATURE_AVAILABILITY,
  CREATE_FEATURE
} from '../constants/API_ENDPOINTS';

export const updateServiceFeatureApi = async (payload) => {
  return request(
    PUT,
    UPDATE_SERVICE_FEATURE(payload?.feature, payload?.status)
  );
};
export const updateServiceFeatureAvailabilityApi = async (payload) => {
  return request(
    PUT,
    UPDATE_SERVICE_FEATURE_AVAILABILITY(payload?.feature, payload?.isNewFeature)
  );
};

export const updateLocationServiceFeatureApi = async (id, data) => {
  return request(PUT, API_UPDATE_LOCATION_SERVICE_FEATURES(id), {data});
};

export const createFeatureApi = async (data) => {
  return request(POST, CREATE_FEATURE(), {data});
};
