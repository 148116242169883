import React, {useEffect, useRef} from 'react';
import {Dialog} from '@material-ui/core';
import T from 'prop-types';
import {Form, Formik} from 'formik';
import {useDispatch, useSelector} from 'react-redux';
import cn from 'classnames';
import NameAndPhoneSchema from 'helpers/services/formValidationSchemas/NameAndPhoneSchema';
import DialogContainer from 'components/DialogContainer/styles';
import DialogTitle from 'components/DialogTitle/styles';
import {DialogBody, FieldInput} from 'routes/App/dashboard/styles';
import DialogFields from 'components/DialogFields/styles';
import DialogField from 'components/DialogField/styles';
import {ErrorSign, ErrorSignWrapper} from 'components/errorSign/errorSign';
import FieldMaskInput from 'components/FieldMaskInput';
import {phoneNumberFormatter} from 'helpers/functionUtils/helpFunctions';
import DialogActions from 'components/DialogActions/styles';
import DialogAction from 'components/DialogAction/styles';
import {ButtonOutline, ButtonPrimary} from 'components/buttons';
import {FieldLabel} from 'components/FieldLabel/styles';
import {DialogSubTitle} from 'components/DialogSubTitle/styles';
import {
  INSIGHTS_GROWTH_OPEN_CONTACT_US_MODAL,
  INSIGHTS_GROWTH_POST_CONTACT_US
} from 'store/insights/growth/action';
import sendGA from 'helpers/services/utils/ga';

const ContactUsModal = ({
  openModal,
  feature,
  featuresConst,
  closeModalAction,
  insightsType
}) => {
  const dispatch = useDispatch();
  const formikRef = useRef();
  const {locationId} = useSelector((state) => state.user);

  useEffect(() => {
    sendGA(
      `Insights ${insightsType}`,
      'Contact us showed',
      `${featuresConst[feature]?.name}`,
      null
    );
  }, []);

  const closeModal = () => {
    dispatch(closeModalAction({isOpen: false, feature: null}));
  };

  const initialValues = {
    Name: '',
    Phone: '',
    CountryCode: '+1'
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      innerRef={formikRef}
      validationSchema={NameAndPhoneSchema}
      onSubmit={(values, {resetForm}) => {
        const body = {
          name: values.Name,
          phone: values.CountryCode + values.Phone
        };
        dispatch(
          INSIGHTS_GROWTH_POST_CONTACT_US({
            body,
            feature: featuresConst[feature].nameForRequest,
            locationId,
            name: featuresConst[feature].name,
            insightsType
          })
        );
        closeModal();
        resetForm();
      }}
    >
      {({values, handleChange, setFieldValue, errors, touched}) => {
        return (
          <Dialog
            open={openModal}
            onClose={() => {
              closeModal();
            }}
            scroll='body'
          >
            <DialogContainer className='DialogMdPadding'>
              <DialogTitle className='DialogTitleMarginBottom'>
                Talk to us about {featuresConst[feature]?.name}
              </DialogTitle>
              <DialogSubTitle className='textCenterBoldMarginBottomLarge'>
                Fill out your details and our team will contact you within the
                next 2 business days
              </DialogSubTitle>
              <DialogBody>
                <Form>
                  <DialogFields>
                    <DialogField className='w100'>
                      <FieldLabel
                        className={cn('FieldLabelLg modalLabel', {
                          error: errors.Name && touched.Name && 'error'
                        })}
                      >
                        Name <span>*</span>
                      </FieldLabel>
                      <FieldInput
                        className={errors.Name && touched.Name && 'error'}
                        name='Name'
                        value={values.Name}
                        placeholder='Name'
                        onChange={handleChange}
                        autoFocus
                      />
                      <ErrorSignWrapper className='positionRight2'>
                        {errors.Name && touched.Name && (
                          <ErrorSign>{errors.Name}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>
                  </DialogFields>
                  <DialogFields className='wAuto'>
                    <DialogField className='w30'>
                      <FieldLabel className='FieldLabelLg modalLabel'>
                        Country code
                      </FieldLabel>
                      <FieldInput
                        onChange={handleChange}
                        value={values.CountryCode}
                        readOnly
                        name='CountryCode'
                        className='isDisabled'
                      />
                    </DialogField>
                    <DialogField className='w70'>
                      <FieldLabel
                        className={cn('FieldLabelLg modalLabel', {
                          error: errors.Phone && touched.Phone && 'error'
                        })}
                      >
                        Phone <span>*</span>
                      </FieldLabel>
                      <FieldMaskInput
                        onChange={(e) => {
                          const formattedPhone = phoneNumberFormatter(
                            e.target.value
                          );
                          setFieldValue('Phone', formattedPhone);
                        }}
                        value={values.Phone}
                        mask='(999) 999-9999'
                        name='Phone'
                        placeholder='Phone'
                        className={errors.Phone && touched.Phone && 'error'}
                      />
                      <ErrorSignWrapper className='positionRight3'>
                        {errors.Phone && touched.Phone && (
                          <ErrorSign>{errors.Phone}</ErrorSign>
                        )}
                      </ErrorSignWrapper>
                    </DialogField>
                  </DialogFields>
                  <DialogActions className='justifyCenter'>
                    <DialogAction className='w40'>
                      <ButtonOutline
                        type='button'
                        onClick={() => {
                          closeModal();
                        }}
                      >
                        <span>Cancel</span>
                      </ButtonOutline>
                    </DialogAction>
                    <DialogAction className='w40'>
                      <ButtonPrimary type='submit'>
                        <span>Submit</span>
                      </ButtonPrimary>
                    </DialogAction>
                  </DialogActions>
                </Form>
              </DialogBody>
            </DialogContainer>
          </Dialog>
        );
      }}
    </Formik>
  );
};

ContactUsModal.propTypes = {
  openModal: T.bool.isRequired,
  feature: T.string.isRequired,
  featuresConst: T.object.isRequired,
  closeModalAction: T.func.isRequired,
  insightsType: T.string.isRequired
};

export default ContactUsModal;
