import qs from 'query-string';
import {
  SERVER_AUTH_URL,
  SERVER_DISCOUNTS_URL,
  SERVER_MENU_SYNC_URL,
  SERVER_TEST_AUTO_URL,
  SERVER_UBEREATS_URL,
  SERVER_URL,
  SERVER_URL_BOOK_A_DRIVER,
  REPORTS_SERVICE_URL,
  SERVER_FEEDBACK_URL,
  SERVER_DISPATCH_URL,
  RDB_SERVICE_URL,
  CORE_ORDERS_SERVICE_URL,
  HUBSPOT_URL,
  SERVER_MARKETING_SERVICE_URL,
  REFUND_CALCULATOR_URL,
  INSIGHTS_SERVICE_URL,
  PAYMENT_ADJUSTMENTS_SERVICE_URL,
  CONFIGURATION_SERVICE_URL,
  RDB_SERVICE_V2_URL
} from 'helpers/services/constants/API_CONSTANTS';

export const GET_ALL_REVIEWS = ({page, limit, sort, sortBy}) =>
  `${SERVER_URL}/reviews?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}`;

export const GET_REVIEW_BY_ID = (id) => `${SERVER_URL}/reviews/${id}`;

export const REPLIES = (id) => `${SERVER_URL}/reviews/${id}/reply`;

export const REPLIED_REVIEWS_BY_USER = (reviewId, userId) =>
  `${SERVER_URL}/reviews/${reviewId}/replies/${userId}`;

// Menu-editor API endpoints

export const UPDATE_SORTING_ORDER = () => `${SERVER_URL}/updateSortingOrder`;

export const MENU_EDITOR_OVERVIEW = ({
  page,
  limit,
  sort,
  sortBy,
  deepLevel = 7
}) =>
  `${SERVER_URL}/overviews?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}&deepLevel=${deepLevel}`;

export const MENU_EDITOR_OVERVIEW_UNAVAILABLE = ({
  page,
  limit,
  sort,
  sortBy,
  deepLevel = 7
}) =>
  `${SERVER_URL}/sections/invisible?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}&deepLevel=${deepLevel}`;

export const GET_MENU_EDITOR_OVERVIEW_BY_ID = (id) =>
  `${SERVER_URL}/sections/${id}`;

export const POST_MENU_EDITOR_OVERVIEW = () => `${SERVER_URL}/sections`;

export const UPDATE_MENU_EDITOR_OVERVIEW = (id) =>
  `${SERVER_URL}/sections/${id}`;

export const PATCH_MENU_EDITOR_SECTION = (id) => `${SERVER_URL}/sections/${id}`;

export const DELETE_MENU_EDITOR_OVERVIEW = (id) =>
  `${SERVER_URL}/sections/${id}`;

export const GET_MENU_EDITOR_ITEMS = ({
  page,
  limit,
  sort,
  sortBy,
  outStock,
  deepLevel = 5
}) =>
  `${SERVER_URL}/menu-items?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}&outStock=${outStock}&deepLevel=${deepLevel}`;

export const GET_MENU_EDITOR_ITEMS_UNAVAILABLE = ({
  page,
  limit,
  sort,
  sortBy,
  outStock,
  deepLevel = 5
}) =>
  `${SERVER_URL}/menu-items/invisible?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}&outStock=${outStock}&deepLevel=${deepLevel}`;

export const GET_MENU_EDITOR_ITEM_BY_ID = (id) =>
  `${SERVER_URL}/menu-items/${id}`;

export const POST_MENU_EDITOR_ITEMS = () => `${SERVER_URL}/menu-items`;

export const UPDATE_MENU_EDITOR_ITEM = (id) => `${SERVER_URL}/menu-items/${id}`;

export const DELETE_MENU_EDITOR_ITEM = (id) => `${SERVER_URL}/menu-items/${id}`;

export const PATCH_MENU_EDITOR_ITEM = (id) => `${SERVER_URL}/menu-items/${id}`;

export const GET_MENU_EDITOR_MODIFIERS = ({page, limit, sort, sortBy}) =>
  `${SERVER_URL}/m-groups?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}&deepLevel=8`;

export const GET_MENU_EDITOR_OUT_STOCK = () => `${SERVER_URL}/out-in-stocks`;

export const GET_MENU_EDITOR_ALL_MODIFIERS = ({
  page,
  limit,
  sort,
  sortBy,
  outStock,
  deepLevel = 5
}) =>
  `${SERVER_URL}/modifiers?fields=standart&search=EGV&page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}&outStock=${outStock}&deepLevel=${deepLevel}`;
export const GET_MENU_EDITOR_MODIFIERS_UNAVAILABLE = ({
  page,
  limit,
  sort,
  sortBy
}) =>
  `${SERVER_URL}/modifiers/invisible?page=${page}&limit=${limit}&sort=${sort}&sortBy=${sortBy}&deepLevel=8`;
// Single modifier

export const GET_MENU_EDITOR_MODIFIER_BY_ID = (id) =>
  `${SERVER_URL}/modifiers/${id}`;

export const PATCH_MENU_EDITOR_MODIFIER = (id) =>
  `${SERVER_URL}/modifiers/${id}`;
export const POST_MENU_EDITOR_MODIFIER = () => `${SERVER_URL}/modifiers`;
export const UPDATE_MENU_EDITOR_MODIFIER = (id) =>
  `${SERVER_URL}/modifiers/${id}`;
export const DELETE_MENU_EDITOR_MODIFIER = (id) =>
  `${SERVER_URL}/modifiers/${id}`;

// Modifier groups
export const GET_MENU_EDITOR_MODIFIER_GROUP_BY_ID = (id) =>
  `${SERVER_URL}/m-groups/${id}`;

export const POST_MENU_EDITOR_MODIFIER_GROUPS = () => `${SERVER_URL}/m-groups`;
export const UPDATE_MENU_EDITOR_MODIFIER_GROUPS = (id) =>
  `${SERVER_URL}/m-groups/${id}`;
export const DELETE_MENU_EDITOR_MODIFIER_GROUPS = (id) =>
  `${SERVER_URL}/m-groups/${id}`;

// User locations API endpoints
export const USER_LOCATIONS_DATA = () => `${SERVER_URL}/locations`;
export const USER_DATA = () => `${SERVER_URL}/users/current`;
export const UPDATE_USER = (userId) => `${SERVER_URL}/users/${userId}`;
export const PATCH_USER = (userId) => `${SERVER_URL}/users/${userId}`;
export const GET_USER_STATISTIC = ({userId, tag, role, sourceType}) =>
  `${SERVER_URL}/app/user-statistic/${userId}?tag=${tag}&role=${role}&sourceType=${sourceType}`;
export const POST_USER_STATISTIC = () => `${SERVER_URL}/app/user-statistic`;
export const SET_BYOC_CONFIGURATION_STATUS = (locationId, status) =>
  `${SERVER_URL}/location/${locationId}/byoc/${status}`;

export const LOCATION_DATA = (id) => `${SERVER_URL}/locations-management/${id}`;
export const LOCATION_SETUP_DATA = (id) =>
  `${SERVER_URL}/locations-management/${id}/setup-data`;
export const LOCATION_NOTIFICATIONS = (locationId, userId) =>
  `${SERVER_URL}/locations-management/${locationId}/${userId}/notifications`;
export const LOCATION_CLOUD_PRINT = (locationId) =>
  `${SERVER_URL}/locations-management/${locationId}/cloud-print`;
export const PATCH_LOCATION = (locationId, id) =>
  `${SERVER_URL}/locations-management/${locationId}/${id}`;
export const UPDATE_SERVICE_FEATURE = (feature, status) =>
  `${SERVER_URL}/locations-management/service-features?feature=${feature}&status=${status}`;
export const UPDATE_SERVICE_FEATURE_AVAILABILITY = (feature, status) =>
  `${SERVER_URL}/locations-management/service-features/availability?feature=${feature}&isNewFeature=${status}`;
export const CREATE_FEATURE = () =>
  `${SERVER_URL}/locations-management/service-features`;

export const POST_FILES = () => `${SERVER_URL}/files`;
export const DELETE_FILE = (file) => `${SERVER_URL}/files/${file}`;

export const GET_MENU_EDITOR_SECTION_TREE = (id) =>
  `${SERVER_URL}/section-tree/${id}`;
export const GET_MENU_EDITOR_ITEM_TREE = (id) =>
  `${SERVER_URL}/menu-item-tree/${id}`;
export const GET_MENU_EDITOR_MODIFIER_GROUP_TREE = (id) =>
  `${SERVER_URL}/m-group-tree/${id}`;
export const GET_MENU_EDITOR_MODIFIER_TREE = (id) =>
  `${SERVER_URL}/modifier-tree/${id}`;
export const GET_MENU_EDITOR_OUT_OFF_STOCK_COUNTERS = () =>
  `${SERVER_URL}/out-off-stocks/count`;
export const GET_MENU_EDITOR_HIDDEN_COUNTERS = () =>
  `${SERVER_URL}/invisible/count`;

// Merchant API endpoints
export const UPDATE_MERCHANT = (id) => `${SERVER_URL}/merchants/${id}`;

// User account setup API endpoints
export const SETUP_ACCOUNT = (lastStep) =>
  `${SERVER_URL}/account?lastStep=${lastStep}`;
export const GET_SETUP_ACCOUNT = (userId) => `${SERVER_URL}/account/${userId}`;

export const POST_STEP_ACCOUNT = (lastStep) =>
  `${SERVER_URL}/signUp2/account?lastStep=${lastStep}`;
export const GET_STEPS_ACCOUNT = (userId) =>
  `${SERVER_URL}/signUp2/account/${userId}`;

export const GET_COUNTRIES_API = () =>
  `${SERVER_URL}/geographi/countries?limit=300`;

export const MERCHANT_URLS = () => `${SERVER_URL}/ops/users/new`;
export const MERCHANT_MENU_QUEUE_UNASSIGNED = () =>
  `${SERVER_URL}/menu-queue/unassigned`;
export const MERCHANT_ACTIVATE = (id) =>
  `${SERVER_URL}/ops/users/${id}/activate`;
export const MERCHANT_VERIFY = (id) => `${SERVER_URL}/ops/users/${id}/verify`;
export const MERCHANT_DEPLOY_OB_AUTOMATION = () =>
  `${SERVER_URL}/ops/users/bot-facebook-deploy`;
export const ACTIVE_MERCHANTS = () => `${SERVER_URL}/ops/users/active`;
export const ACTIVE_MERCHANT_BY_ID = (id) =>
  `${SERVER_URL}/ops/users/active/${id}`;
export const MERCHANT_URLS_ID = (id) => `${SERVER_URL}/locations/${id}`;
export const MERCHANT_CHANGE_USER = (id) => `${SERVER_URL}/user-auth/${id}`;
export const MERCHANT_PUT = (id) => `${SERVER_URL}/ops/users/${id}`;
export const LOCATION_ACTIVATE = (id) =>
  `${SERVER_URL}/ops/locations/${id}/activate`;
export const LOCATION_PUT = (id) => `${SERVER_URL}/ops/locations/${id}`;
export const API_UPDATE_LOCATION_SERVICE_FEATURES = (id) =>
  `${SERVER_URL}/ops/locations/feature-management/${id}`;
export const API_UPDATE_LOCATION_SALES_PACKAGE = (id) =>
  `${SERVER_URL}/ops/locations/sales-package/${id}`;
export const API_UPDATE_LOCATION_GMB_REVIEW_LINK = (id) =>
  `${SERVER_URL}/ops/locations/gmb-review-link/${id}`;

// ADMIN SUDO USERS

export const GET_SUDO_USERS_API = () => `${SERVER_URL}/ops/sudo-users`;
export const SUDO_USER_API = (id) => `${SERVER_URL}/ops/sudo-users/${id}`;
export const GET_ALL_SUDO_USER_FEATURE_FLAGS_API = () =>
  `${SERVER_URL}/app/sudo-users-feature-flags`;

// VIRTUAL NUMBER

export const LOCATION_VN_STATUS = (locationId) =>
  `${SERVER_URL}/virtual-numbers/${locationId}/status`;
export const API_CREATE_VN = (locationId) =>
  `${SERVER_URL}/virtual-numbers/${locationId}`;
export const API_UPDATE_VN = (locationId) =>
  `${SERVER_URL}/virtual-numbers/${locationId}`;
export const API_CREATE_TWILIO = (locationId) =>
  `${SERVER_URL}/virtual-numbers/${locationId}/create-twilio`;
export const API_ACTIVATE_VN = (locationId) =>
  `${SERVER_URL}/virtual-numbers/${locationId}/activate`;
export const API_DISABLE_VN = (locationId) =>
  `${SERVER_URL}/virtual-numbers/${locationId}/disable`;

// Admin panel API endpoints
export const GET_ADMIN_USERS = () => `${SERVER_URL}/ops/users`;
export const GET_ADMIN_MERCHANTS = () => `${SERVER_URL}/ops/merchants`;
export const GET_ADMIN_LOCATIONS = (merchantId) =>
  `${SERVER_URL}/ops/locations/${merchantId}`;
export const RESET_PASSWORD_ADMIN_API = (_id) =>
  `${SERVER_URL}/users/reset/${_id}`;
export const TEST_AUTOMATION_JOB_ID = (id) =>
  `${SERVER_URL}/ops/locations/${id}/set-test-job-id`;
export const TEST_AUTOMATION_RESULT = (id) =>
  `${SERVER_TEST_AUTO_URL}/api/result?result_id=${id}&extra=true`;
export const TEST_AUTOMATION = () => `${SERVER_TEST_AUTO_URL}/api/test`;

// Admin panel real time board

export const GET_ORDERS_RTB = () =>
  `${RDB_SERVICE_URL}/api/v1/get-delivery-orders`;

// New RTB

export const GET_ORDERS_RTB_V2 = () =>
  `${RDB_SERVICE_V2_URL}/api/delivery-orders/rdb`;

export const GET_ORDER_RTB_V2 = (id) =>
  `${RDB_SERVICE_V2_URL}/api/delivery-orders/rdb/${id}`;

export const POST_ORDER_RTB_V2_ACTION = (id) =>
  `${RDB_SERVICE_V2_URL}/api/delivery-orders/${id}/report-actions`;

export const REMOVE_ORDER_RTB_V2 = (id) =>
  `${RDB_SERVICE_V2_URL}/api/delivery-orders/${id}/remove`;

// FIND DELIVERY

export const FIND_DELIVERY = (id) =>
  `${RDB_SERVICE_V2_URL}/api/delivery-orders/find/${id}`;

// Authentication API endpoints
export const LOGIN_USER = () => `${SERVER_AUTH_URL}/login`;
export const UPDATE_TOKENS = () => `${SERVER_AUTH_URL}/refresh`;
export const DELETE_REFRESH_TOKEN = (userId) =>
  `${SERVER_AUTH_URL}/refresh/${userId}`;
export const SEND_MAIL = (email) =>
  `${SERVER_AUTH_URL}/reset_password/${email}`;
export const UPDATE_PASSWORD = (token) =>
  `${SERVER_AUTH_URL}/update_password/${token}`;
export const UPDATE_MEMBER_PASSWORD = (token) =>
  `${SERVER_AUTH_URL}/update_member_password/${token}`;
export const SAVE_USER_EMAIL = () => `${SERVER_URL}/registration`;
export const SIGNUP_USER = (email) => `${SERVER_URL}/registration/${email}`;
export const SELF_SIGNUP_USER = (email) =>
  `${SERVER_URL}/signUp2/registration/${email}`;
export const RETURN_TO_DASHBOARD = () =>
  `${SERVER_AUTH_URL}/return_to_old_dashboard`;

/* ORDERS */
export const API_ORDERS = (queryObj) =>
  `${SERVER_URL}/orders?${qs.stringify(queryObj)}`;
export const API_ORDER = (id) => `${SERVER_URL}/orders/${id}`;
export const API_DISPATCH_INFO = (id) =>
  `https://t.sauce.rest/api/tracking?order_id=${id}`;
export const API_ACTIVE_ORDERS = () => `${SERVER_URL}/orders/tab/active`;
export const API_TEST_ORDER = () => `${SERVER_URL}/orders/test`;
export const API_ORDER_INFO = (id) => `${SERVER_URL}/orders/info/${id}`;
export const API_ORDER_REFUND = (invoiceId) =>
  `${PAYMENT_ADJUSTMENTS_SERVICE_URL}/api/payment-adjustments/v1/orders/${invoiceId}/refunds/restaurant/process`;
export const API_GET_ORDER_REFUND_DATA = (invoiceId) =>
  `${PAYMENT_ADJUSTMENTS_SERVICE_URL}/api/payment-adjustments/v1/orders/${invoiceId}/refunds/restaurant/get-available-items`;
export const API_CALCULATE_ORDER_ADJUST = (invoiceId) =>
  `${PAYMENT_ADJUSTMENTS_SERVICE_URL}/api/payment-adjustments/v1/orders/${invoiceId}/price-adjustments/calculate`;
export const API_CREATE_ORDER_ADJUST = (invoiceId) =>
  `${PAYMENT_ADJUSTMENTS_SERVICE_URL}/api/payment-adjustments/v1/orders/${invoiceId}/price-adjustments/create`;
export const API_GET_DELIVERY_STATUS = (deliveryId) =>
  `${SERVER_URL}/order/${deliveryId}/delivery-status`;
export const API_CANCEL_ORDER_DELIVERY = (deliveryId) =>
  `${SERVER_URL}/order/${deliveryId}/cancel-delivery`;

// BOOK A DRIVER

export const API_BAD_ORDER = () => `${SERVER_URL}/book-a-driver`;
export const API_BAD_ORDER_ACTIVE = (config) =>
  `${SERVER_URL_BOOK_A_DRIVER}/order/active?page=${config.page}&perPage=${config.perPage}&sortField=${config.sortField}&sortDir=${config.sortDir}&from=${config.from}&to=${config.to}`;
export const API_BAD_ORDER_HISTORY = (config) =>
  `${SERVER_URL_BOOK_A_DRIVER}/order/inactive?page=${config.page}&perPage=${config.perPage}&sortField=${config.sortField}&sortDir=${config.sortDir}`;
export const API_BAD_ORDER_DELIVERED = (config) =>
  `${SERVER_URL_BOOK_A_DRIVER}/order/by-status?status=${config.status}&status=scheduled&status=assigned&page=${config.page}&perPage=${config.perPage}&sortField=${config.sortField}&sortDir=${config.sortDir}&from=${config.from}&to=${config.to}`;
export const API_BAD_ORDER_DETAILS = (id) =>
  `${SERVER_URL_BOOK_A_DRIVER}/order/${id}`;
export const API_BAD_ADDRESSES = (phone) =>
  `${SERVER_URL_BOOK_A_DRIVER}/order/addresses?phone=${phone}`;
export const API_BAD_DETAILS_NOTES = (id) =>
  `${SERVER_URL_BOOK_A_DRIVER}/order/${id}/internal-notes`;
export const API_BAD_CHECK_ADDRESS = () => `${SERVER_URL_BOOK_A_DRIVER}/quote`;
// Analytics

export const API_GET_TOTAL_CUSTOMERS = (startDate, endDate) =>
  `${SERVER_URL}/analytics/totalCustomers${
    startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''
  }`;
export const API_GET_TOTAL_SALES = (startDate, endDate) =>
  `${SERVER_URL}/analytics/totalSales${
    startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''
  }`;
export const API_GET_SALES_PER_HOUR = (timezone, startDate, endDate) =>
  `${SERVER_URL}/analytics/salesByHours${
    startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''
  }`;
export const API_GET_SALES_PER_ITEM = (startDate, endDate) =>
  `${SERVER_URL}/analytics/salesByItems${
    startDate && endDate ? `?startDate=${startDate}&endDate=${endDate}` : ''
  }`;

// MenuQueue

export const API_DELETE_MENU_QUEUE = (id) =>
  `${SERVER_URL}/menu-queue?id=${id}`;
export const API_CRUD_MENU_QUEUE = () => `${SERVER_URL}/menu-queue`;

// Reports
export const API_GENERATE_REPORT_SALES_SUMMARY = `${REPORTS_SERVICE_URL}/sales-reports/create`;
export const API_EXPORT_REPORT_SALES_SUMMARY = (reportId) =>
  `${REPORTS_SERVICE_URL}/sales-reports/${reportId}/export/xlsx`;

export const API_GENERATE_REPORTS_CUSTOMERS = `${REPORTS_SERVICE_URL}/customer-reports/create`;
export const API_EXPORT_REPORT_CUSTOMERS = (reportId) =>
  `${REPORTS_SERVICE_URL}/customer-reports/${reportId}/export/xlsx`;

export const API_GENERATE_REPORT_PAYOUT_SUMMARY = `${REPORTS_SERVICE_URL}/payouts-reports/create`;
export const API_EXPORT_REPORT_PAYOUT_SUMMARY = (reportId) =>
  `${REPORTS_SERVICE_URL}/payouts-reports/${reportId}/export/xlsx`;

export const API_GENERATE_REPORT_BOOK_A_DRIVER = `${REPORTS_SERVICE_URL}/bookadriver-reports/create`;
export const API_GET_REPORTS_ORDERS_BOOK_A_DRIVER = (reportId, skip) =>
  `${REPORTS_SERVICE_URL}/odata/bookadriver-orders/${reportId}?$top=7&skip=${skip}&count=true`;
export const API_EXPORT_REPORT_BOOK_A_DRIVER = (reportId) =>
  `${REPORTS_SERVICE_URL}/bookadriver-reports/${reportId}/export/xlsx`;

export const API_GENERATE_REPORT_BYOC = `${REPORTS_SERVICE_URL}/byoc-reports/create`;
export const API_GET_REPORTS_ORDERS_BYOC = (reportId, skip) =>
  `${REPORTS_SERVICE_URL}/odata/byoc-orders/${reportId}?$top=7&skip=${skip}&count=true`;
export const API_EXPORT_REPORT_BYOC = (reportId) =>
  `${REPORTS_SERVICE_URL}/byoc-reports/${reportId}/export/xlsx`;

export const API_GENERATE_REPORT_REFUNDS_SUMMARY = `${REPORTS_SERVICE_URL}/refunds-reports/create`;
export const API_GENERATE_REPORT_FEEDBACK_SUMMARY = `${REPORTS_SERVICE_URL}/feedback-reports/create`;
export const API_GET_REPORTS_CONTENT_REFUNDS_SUMMARY = (reportId) =>
  `${REPORTS_SERVICE_URL}/refunds-reports/${reportId}/refunds/query/dynamic-linq?orderBy=orderDate&take=1000`;
export const API_GET_REPORTS_CONTENT_FEEDBACK_SUMMARY = (reportId) =>
  `${REPORTS_SERVICE_URL}/feedback-reports/${reportId}/reviews/query/dynamic-linq?take=1000`;
export const API_EXPORT_REPORT_REFUNDS_SUMMARY = (reportId) =>
  `${REPORTS_SERVICE_URL}/refunds-reports/${reportId}/export/xlsx`;
export const API_EXPORT_REPORT_FEEDBACK_SUMMARY = (reportId) =>
  `${REPORTS_SERVICE_URL}/feedback-reports/${reportId}/export/xlsx`;

// UserManagement

export const API_GET_USERS = (merchant_name, page, sort, search, limit) =>
  `${SERVER_URL}/users?merchant=${merchant_name}&page=${page}&sort=${sort}&search=${search}&limit=${limit}`;
export const API_GET_COUNTER = (merchant_name) =>
  `${SERVER_URL}/users/stats?merchant=${merchant_name}`;
export const API_DELETE_USER = (id) => `${SERVER_URL}/users/${id}`;
export const API_CREATE_USER = () => `${SERVER_URL}/users`;
export const API_USER_UPDATE_PASSWORD = (id) =>
  `${SERVER_URL}/users/reset/${id}`;

// AppSettings
export const API_GET_MENU_ITEMS = () => `${SERVER_URL}/app/service-items`;

// Discounts
export const API_CRUD_DISCOUNT = (discountId) =>
  `${SERVER_DISCOUNTS_URL}/api/discounts/${discountId}`;
export const API_CRUD_DISCOUNTS = (status) =>
  `${SERVER_DISCOUNTS_URL}/api/discounts?status=${status}`;
export const PUBLISH_DISCOUNTS = (menuConfigId) =>
  `${SERVER_MENU_SYNC_URL}/sync-bot/${menuConfigId}`;
export const API_CREATE_DISCOUNT = () =>
  `${SERVER_DISCOUNTS_URL}/api/discounts`;

// Uber Eats
export const GET_UBER_EATS_MERCHANT_STATUS = (merchantId) =>
  `${SERVER_UBEREATS_URL}/api/uber-eats-adapter/v1/merchants/${merchantId}`;
export const GET_UBER_EATS_STORES = (merchantId) =>
  `${SERVER_UBEREATS_URL}/api/uber-eats-adapter/v1/merchants/${merchantId}/stores`;
export const GET_UBER_EATS_SINGLE_STORE = (storeId) =>
  `${SERVER_UBEREATS_URL}/api/uber-eats-adapter/v1/stores/${storeId}`;
export const ACTIVATE_UBER_EATS_STORE = (storeId) =>
  `${SERVER_UBEREATS_URL}/api/uber-eats-adapter/v1/stores/${storeId}/activate`;
export const SEND_SLACK_NOTIFICATION_BYOC = () =>
  `${SERVER_URL}/uberEats/send-notification`;
export const SEND_SLACK_NOTIFICATION_UE_DISCONNECT = () =>
  `${SERVER_URL}/uberEats/send-notification-disconnect`;
export const GET_UBER_EATS_STORE_MENUS = (storeId) =>
  `${SERVER_UBEREATS_URL}/api/uber-eats-adapter/v1/stores/${storeId}/menus`;
export const POST_UBER_EATS_ONBOARDING_PROCESS = () =>
  `${SERVER_UBEREATS_URL}/api/uber-eats-adapter/v1/onboarding`;

// Hubspot
export const POST_HUBSPOT_COMMENT = () => `${SERVER_URL}/hubspot/send-comment`;
export const GET_HUBSPOT_INFO = () => `${SERVER_URL}/hubspot-info`;

// Feedback
export const API_FEEDBACK_STATS = (feedbackType, startDate, endDate) =>
  `${SERVER_FEEDBACK_URL}/api/v1/survey/stats?type=${feedbackType}&from=${startDate}&to=${endDate}`;

// Refunds Calculator
export const RC_GET_ORDER = (invoiceId) =>
  `${REFUND_CALCULATOR_URL}/${invoiceId}/refunds/info`;
export const RC_GENERATE_ORDER_REFUND = (
  id
) => `${REFUND_CALCULATOR_URL}/${id}/refunds/calculate
`;
export const RC_MAKE_ORDER_REFUND = (
  id
) => `${REFUND_CALCULATOR_URL}/${id}/refunds/process
`;

// Email Marketing

export const POST_CONTACT_FORM_EMAIL_MARKETING = () =>
  `${SERVER_URL}/email-marketing/contact-us`;
export const GET_MARKETING_STATISTIC = (locationId) =>
  `${SERVER_MARKETING_SERVICE_URL}/api/marketing/v1/brevo/events/summary/${locationId}`;

// Flyers
export const SEND_FLYERS_REQUEST = () =>
  `${SERVER_MARKETING_SERVICE_URL}/api/flyers/v1/flyers-request/create`;

// Insights Growth

export const GET_INSIGHTS_GROWS_INFO = (locationId) =>
  `${INSIGHTS_SERVICE_URL}/api/v1/dashboard/locations/${locationId}/modules`;

export const POST_INSIGHTS_GROWTH_CONTACT_US = (id, feature) =>
  `${INSIGHTS_SERVICE_URL}/api/v1/dashboard/locations/${id}/modules/${feature}/enable`;

// Insights Performance

export const GET_INSIGHTS_PERFORMANCE_INFO = (locationId, from, to) =>
  `${INSIGHTS_SERVICE_URL}/api/v1/dashboard/locations/${locationId}/performance/metrics${
    from && to ? `?from=${from}&to=${to}` : ''
  }`;
export const GET_INSIGHTS_PERFORMANCE_SETTINGS_DATES = (locationId) =>
  `${INSIGHTS_SERVICE_URL}/api/v1/dashboard/locations/${locationId}/performance/metrics/metadata`;

export const GET_INSIGHTS_PERFORMANCE_SETTINGS_INFO = (locationId) =>
  `${INSIGHTS_SERVICE_URL}/api/v1/dashboard/locations/${locationId}/performance/settings`;

export const POST_INSIGHTS_PERFORMANCE_SETTINGS_INFO = (locationId) =>
  `${INSIGHTS_SERVICE_URL}/api/v1/dashboard/locations/${locationId}/performance/settings`;

export const GET_LOCATIONS_WITH_INSIGHTS = (search) =>
  `${SERVER_URL}/location-management/insights${
    search ? `?search=${search}` : ''
  }`;
export const POST_INSIGHTS_GROWTH_MODULES = (id) =>
  `${INSIGHTS_SERVICE_URL}/api/v1/dashboard/locations/${id}/module/update`;

// Phone Ordering
/**
 * @param locationId
 */
export const API_TOGGLE_PHONE_ORDERING = (locationId) =>
  `${SERVER_URL}/virtual-numbers/sudo/phone-ordering/${locationId}/toggle`;

export const API_TOGGLE_ALL_LOCATIONS_PHONE_ORDERING = () =>
  `${SERVER_URL}/virtual-numbers/sudo/phone-ordering/toggle`;

export const API_GET_LOCATIONS_PHONE_ORDERING = () =>
  `${SERVER_URL}/virtual-numbers/sudo/phone-ordering`;

/**
 * @param locationId
 */
export const API_UPDATE_PHONE_ORDERING = (locationId) =>
  `${SERVER_URL}/virtual-numbers/sudo/phone-ordering/${locationId}`;

export const API_PHONE_ORDERING_SETTINGS = () =>
  `${SERVER_URL}/virtual-numbers/sudo/phone-ordering/settings`;

export const API_GET_MARKETING_INTEGRATIONS_INFO = (locationId) =>
  `${CONFIGURATION_SERVICE_URL}/api/configuration/v1/analytics-integration/locations/${locationId}`;
export const API_ENABLE_MARKETING_INTEGRATION = (
  locationId,
  integration,
  query
) =>
  `${CONFIGURATION_SERVICE_URL}/api/configuration/v1/analytics-integration/locations/${locationId}/${integration}/enable?${query}`;
export const API_DISABLE_MARKETING_INTEGRATION = (locationId, integration) =>
  `${CONFIGURATION_SERVICE_URL}/api/configuration/v1/analytics-integration/locations/${locationId}/${integration}/disable`;

export const API_GET_MERCHANTS_FROM_CONFIGURATION_SERVICE = (search) =>
  `${CONFIGURATION_SERVICE_URL}/api/configuration/v1/dashboard/merchants/autocomplete?term=${search}&limit=20`;
