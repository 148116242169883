import {createReducer} from 'redux-act';
import {
  GET_MERCHANTS,
  GET_MERCHANTS_SUCCESS,
  CONNECT_MERCHANT_TO_STREAM
} from 'store/global/connectingStream/action';

const initialState = () => ({
  merchants: [],
  loading: false
});

const connectingStreamReducer = createReducer(
  {
    [GET_MERCHANTS]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_MERCHANTS_SUCCESS]: (state, payload) => ({
      ...state,
      loading: false,
      merchants: payload
    }),
    [CONNECT_MERCHANT_TO_STREAM]: (state) => ({
      ...state,
      loading: true
    })
  },
  initialState()
);

export default connectingStreamReducer;
