import React, {memo} from 'react';
import PropTypes from 'prop-types';
import TableContainer from '@material-ui/core/TableContainer';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import {FeatureManagementTableWrapper, TableText} from './style';
import Loading from '../../../../../components/shared/Utils/Loading';
import PopperActions from '../../../../../components/PopperActions';
import {FlexContainer} from '../../../../../components/layout';
import {SidebarNew} from '../../../../../components/layout/sidebar/styles';

const FeatureManagementTable = ({
  headCells,
  items = [],
  loading,
  onStatusHandle,
  onStatusAvailabilityHandle
}) => {
  if (!items.length && !loading) return <div>Features are not found!</div>;

  console.log(items);

  return (
    <FeatureManagementTableWrapper>
      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((headCell) => (
                <TableCell key={headCell.id}>{headCell.label}</TableCell>
              ))}
              <TableCell style={{width: '5%'}} />
            </TableRow>
          </TableHead>
          {loading ? (
            <Loading />
          ) : (
            <TableBody>
              {items &&
                items.map((row) => (
                  <TableRow key={row.id} tabIndex={-1}>
                    {headCells.map(({id}) => (
                      <TableCell align='left' key={id}>
                        <FlexContainer>
                          <TableText>{String(row[id])}</TableText>
                          {id === 'name' && row?.isNewFeature && (
                            <SidebarNew>New</SidebarNew>
                          )}
                        </FlexContainer>
                      </TableCell>
                    ))}
                    <TableCell align='right'>
                      <PopperActions
                        popperActions={() => [
                          {
                            title: !row.enabled
                              ? 'Enable Feature'
                              : 'Disable Feature',
                            action: () => onStatusHandle(row)
                          },
                          {
                            title: !row.isNewFeature
                              ? 'Mark as New'
                              : 'Remove New Mark',
                            action: () => onStatusAvailabilityHandle(row)
                          }
                        ]}
                        row={row}
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </FeatureManagementTableWrapper>
  );
};

FeatureManagementTable.propTypes = {
  headCells: PropTypes.array.isRequired,
  items: PropTypes.array.isRequired,
  onStatusHandle: PropTypes.func.isRequired,
  onStatusAvailabilityHandle: PropTypes.func.isRequired,
  loading: PropTypes.bool
};

FeatureManagementTable.defaultProps = {
  loading: false
};

export default memo(FeatureManagementTable);
