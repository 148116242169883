// How many items to display per page (SalesByItems)
export const ITEMS_PER_PAGE = 14;

// Table HeadCells
export const HeadCells = [
  {id: 'rank', numeric: true, label: 'Rank'},
  {id: 'name', numeric: false, label: 'Item', alignLeft: true},
  {
    id: 'totalOrders',
    numeric: true,
    label: 'Orders (total)',
    alignLeft: true
  },
  {
    id: 'totalSales',
    numeric: true,
    label: 'Sales (total)',
    alignLeft: true
  }
];

export const ReviewsHeadCells = [
  {id: 'UserNameId', numeric: false, label: 'User Name', sort: true},
  {id: 'FoodFeedbacks', numeric: false, label: 'Feedback', sort: true},
  {id: 'EndTime', numeric: false, label: 'Date', sort: true},
  {
    id: 'Delivery.DeliveryType',
    numeric: false,
    label: 'Order Type',
    sort: true
  },
  {id: 'OrderSize', numeric: false, label: 'Order Size', sort: true}
  // {
  //   id: 'Delivery.DeliveryTyp',
  //   numeric: false,
  //   label: 'Reviewed Status',
  //   sort: true
  // }
  // {id: 'replyButton', numeric: false, label: '', sort: false}
];

export const DiscountsHeadCells = [
  {id: 'DeliveryName', numeric: false, label: 'Name'},
  {id: 'OrdersCount', numeric: false, label: 'Orders'},
  {id: 'SubTotal', numeric: false, label: 'Sub Total'},
  {id: 'DeliveryActivation', numeric: false, label: 'Activation'}
];

export const TeamHeadCells = [
  {id: 'first_name', numeric: false, label: 'User Name', sort: true},
  {id: 'email', numeric: false, label: 'Email', sort: false},
  {id: 'role', numeric: false, label: 'Role', sort: false},
  {id: 'last_sign_in', numeric: false, label: 'Last sign in', sort: false}
];

export const MembersHeadCells = [
  {id: 'first_name', numeric: false, label: 'First Name', sort: false},
  {id: 'last_name', numeric: false, label: 'Last Name', sort: false},
  {id: 'email', numeric: false, label: 'Email', sort: false},
  {id: 'role', numeric: false, label: 'Role', sort: false}
];

export const ORDER_DIRECTION = {
  ASC: 'asc',
  DESC: 'desc'
};

export const Items = [
  {
    rank: 2,
    name: '2222',
    totalOrders: 45,
    totalSales: 50
  },
  {
    rank: 28,
    name: '6666666',
    totalOrders: 4545,
    totalSales: 50
  }
];

export const MenuQueueHeadCells = [
  {id: 'wix_id', numeric: false, label: 'Wix Id', sort: false},
  {id: 'menu_site_name', numeric: false, label: 'Menu Site Name', sort: false},
  {id: 'site_link', numeric: false, label: 'Site Link', sort: false},
  {id: 'web_site_type', numeric: false, label: 'Site Type', sort: false},
  {id: 'template', numeric: false, label: 'Template', sort: false},
  {id: 'assign', numeric: false, label: 'Assign', sort: false}
];

export const FeatureManagementHeadCells = [
  {id: 'name', label: 'Feature Name'},
  {id: 'route', label: 'Feature Route'},
  {id: 'enabled', label: 'Feature Enabled'},
  {id: 'showOnTablet', label: 'Available On Tablet'},
  {id: 'isNewFeature', label: 'New Feature'}
];
