import React, {useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import SauceLogoRed from 'assets/logo-sauce-red.svg';
import {
  SetupContainer,
  SetupHeader,
  SetupHolder,
  SetupLogo,
  SetupSection
} from 'routes/Auth/registration/signUpNew/style';
import Subtitle5 from 'components/typography/Subtitle5';
import ConnectStreamModal from 'routes/App/ConnectingStream/components/Modals/ConnectStreamModal';
import {removeSessionStorageItem} from '../../../helpers/sessionStorage';

const ConnectingStream = () => {
  const history = useHistory();
  const location = useLocation();
  const queryString = location.search;
  const urlParams = new URLSearchParams(queryString);
  const streamState = urlParams.get('state');

  useEffect(() => {
    if (!streamState) {
      removeSessionStorageItem('streamUrl');
      history.push('/');
    }
  }, []);

  return (
    <>
      <SetupSection>
        <SetupHolder>
          <SetupContainer>
            <SetupHeader className='Centered'>
              <SetupLogo src={SauceLogoRed} />
              <Subtitle5>
                <span>Connect</span> your Stream
              </Subtitle5>
            </SetupHeader>
          </SetupContainer>
        </SetupHolder>
      </SetupSection>
      <ConnectStreamModal />
    </>
  );
};

export default ConnectingStream;
