import styled from 'styled-components';

export const DashboardInner = styled.div`
  background-color: #fff;
  border-radius: 10px;
  padding: 20px 30px;
  margin-top: 20px;
  position: relative;
  box-shadow: 1px 10px 10px rgba(108, 49, 151, 0.08),
    10px 10px 20px rgba(108, 49, 151, 0.06),
    30px 30px 30px rgba(108, 49, 151, 0.04);
  @media (max-width: 1024px) {
    padding: 20px 24px;
  }
  @media (max-width: 767px) {
    padding: 17px 15px;
  }
  &.mTop40 {
    margin-top: 40px;
  }
  &.mTop0 {
    margin-top: 0;
  }
  &.mTop15 {
    margin-top: 15px;
  }
  &.mTop30 {
    margin-top: 30px;
  }
  &.RefundContainer {
    overflow: auto;
    background-color: transparent;
    box-shadow: none;
    margin-top: 0;
    padding-top: 0;
  }
  &.DashboardInnerW50 {
    flex: 0 0 calc(50% - 20px);
    margin: 20px 10px 0 10px;

    @media (max-width: 1650px) {
      margin: 20px 0 0 0;
    }
  }
  &.paddingSm {
    padding: 24px;
    @media (max-width: 1024px) {
      padding: 20px;
    }
    @media (max-width: 767px) {
      padding: 17px 15px;
    }
  }
  &.paddingLg {
    padding: 30px;
    @media (max-width: 1024px) {
      padding: 20px 24px;
    }
    @media (max-width: 767px) {
      padding: 17px 15px;
    }
  }
  &.paddingXl {
    padding: 38px 30px;
    @media (max-width: 1024px) {
      padding: 16px 18px;
    }
    @media (max-width: 767px) {
      padding: 15px;
    }
  }

  &.paddingXXl {
    padding: 50px 34px 54px 34px;
    @media (max-width: 1023px) {
      padding: 30px 34px 54px 34px;
    }
    @media (max-width: 767px) {
      padding: 30px 15px 44px 15px;
    }
  }
  &.withoutShadow {
    box-shadow: none;
  }
  &.FullHeight {
    min-height: calc(100vh - 200px);
  }
  &.paddingBottomXl {
    padding-bottom: 54px;
  }
  &.orderDetailsPaddingBottom {
    padding-bottom: 64px;
    @media (max-width: 1023px) {
      padding-bottom: 50px;
    }
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }
  &.paddingReportsDataPicker {
    padding: 28px 35px 23px 35px;
    @media (max-width: 1350px) {
      padding: 28px 30px 23px 30px;
    }
    @media (max-width: 1023px) {
      padding: 20px 24px 15px 24px;
    }
    @media (max-width: 767px) {
      padding: 17px 15px;
    }
  }

  &.w50 {
    margin: 12px;
    flex: 0 0 calc(50% - 24px);
    @media (max-width: 1000px) {
      flex: 0 0 calc(100% - 24px);
    }
  }
  &.paddingRefundsTopPanel {
    padding: 32px 25px 27px 27px;
  }

  &.PopularAnswerInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &.RefundsCalculator {
    max-width: 1088px;
    padding: 20px 35px;

    @media (max-width: 1023px) {
      padding: 20px;
    }

    &.paddingLg {
      padding: 35px;
      @media (max-width: 1023px) {
        padding: 20px;
      }
    }
  }
  &.PaddingInsightsPerformanceDatePicker {
    padding: 24px 40px;
    margin: 20px 0 0 0;
  }
  &.CheckedIntegrationsBlock {
    display: flex;
    flex-direction: column;
    justify-content: end;
  }
  &.IntegrationsDetails {
    max-width: 630px;
  }
  &.PaddingMarketingIntegrations {
    padding: 24px 40px;
    margin: 20px 16px 0 16px;
  }
  &.NoPermission {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`;

export default DashboardInner;
