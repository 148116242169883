import {createReducer} from 'redux-act';
import {
  GET_MERCHANTS_URLS,
  GET_MERCHANTS_URLS_SUCCESS,
  PAGE_CHANGE,
  SEARCH_CHANGE,
  GET_MERCHANT,
  SORT_CHANGE,
  GET_MERCHANT_SUCCESS,
  CLEAR_MERCHANT,
  GET_UNASSIGNED_MENU_QUEUE_SUCCESS,
  CLEAR_FILTERS,
  FACEBOOK_INFO_MODAL,
  DEPLOY_OB_AUTOMATION,
  CLEAR_NEW_OWNER,
  SET_OWNER_CREATION_MERCHANT_ID,
  CLEAR_OWNER_CREATION_MERCHANT_ID,
  CHANGE_LOCATION_SUCCESS,
  CHANGE_LOCATION,
  UPDATE_ACTIVE_MERCHANT,
  UPDATE_ACTIVE_MERCHANT_SUCCESS,
  SET_TEST_AUTOMATION_RESULT,
  SET_TEST_AUTOMATION_JOB_ID,
  SET_SALES_PACKAGE_SUCCESS,
  SET_GMB_REVIEW_LINK_SUCCESS,
  GET_FEATURE_FLAGS_SUCCESS
} from 'store/merchants/action';
import MERCHANT, {HIDDEN_FEATURE_FLAGS} from 'helpers/constants/merchants';
import {ORDER_DIRECTION} from 'helpers/constants/Table';

const initialPaging = {
  total: 0,
  limit: 10,
  pages: 0,
  page: 0
};

const initialSort = {
  sortBy: MERCHANT.MERCHANT_NAME,
  sortDirection: ORDER_DIRECTION.ASC
};

const initialState = () => ({
  loading: false,
  isUpdating: false,
  paging: initialPaging,
  search: '',
  sort: initialSort,
  form: '',
  selectedRecord: null,
  locationTestJobStatuses: {},
  ownerCreationMerchantId: null,
  data: [],
  webConfig: [],
  menuConfig: [],
  facebookPageInfoRequired: {},
  facebookBotProcessing: false,
  dashboardFeatureFlags: [],
  advanceDashboardFeatureFlags: []
});

const getWithLastTestOrderJobId = (id, locations, jobId) => {
  return locations?.map((location) =>
    location.id === id
      ? {
          ...location,
          lastTestAutomationJob: {id: jobId}
        }
      : location
  );
};

const merchantsReducer = createReducer(
  {
    [GET_MERCHANTS_URLS]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [GET_MERCHANTS_URLS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: payload.docs,
        paging: {
          ...state.paging,
          total: payload.totalDocs,
          pages: payload.totalPages
        },
        loading: false
      };
    },
    [GET_FEATURE_FLAGS_SUCCESS]: (state, payload) => {
      const advanceDashboardFeatureFlags = payload?.dashboardItems?.map(
        (el) => el?.id
      );
      const dashboardFeatureFlags = advanceDashboardFeatureFlags.filter(
        (item) => !HIDDEN_FEATURE_FLAGS.includes(item)
      );
      return {
        ...state,
        dashboardFeatureFlags,
        advanceDashboardFeatureFlags
      };
    },
    [PAGE_CHANGE]: (state, payload) => {
      return {
        ...state,
        paging: {
          ...state.paging,
          page: payload
        }
      };
    },
    [GET_MERCHANT]: (state) => {
      return {
        ...state,
        loading: true
      };
    },
    [GET_MERCHANT_SUCCESS]: (state, payload) => {
      return {
        ...state,
        selectedRecord: payload,
        loading: false
      };
    },
    [CLEAR_MERCHANT]: (state) => {
      return {
        ...state,
        selectedRecord: null
      };
    },
    [SEARCH_CHANGE]: (state, payload) => {
      return {
        ...state,
        search: payload,
        paging: initialPaging
      };
    },
    [SORT_CHANGE]: (state, payload) => {
      return {
        ...state,
        sort: {
          sortBy: payload.sortBy,
          sortDirection: payload.sortDirection
        }
      };
    },
    [CLEAR_FILTERS]: (state) => {
      return {
        ...state,
        search: '',
        sort: initialSort,
        paging: initialPaging
      };
    },
    [GET_UNASSIGNED_MENU_QUEUE_SUCCESS]: (state, payload) => {
      return {
        ...state,
        webConfig: payload?.filter((item) => item.web_site_type === 'Web'),
        menuConfig: payload?.filter((item) => item.web_site_type === 'Menu')
      };
    },
    [FACEBOOK_INFO_MODAL]: (state, payload) => {
      return {
        ...state,
        facebookPageInfoRequired: payload,
        facebookBotProcessing: false
      };
    },
    [DEPLOY_OB_AUTOMATION]: (state) => {
      return {
        ...state,
        facebookBotProcessing: true
      };
    },
    [CHANGE_LOCATION]: (state, payload) => {
      return {
        ...state,
        selectedRecord: {
          ...state.selectedRecord,
          Locations: state.selectedRecord.Locations.map((location) =>
            location.id === payload.id ? payload : location
          )
        }
      };
    },
    [CHANGE_LOCATION_SUCCESS]: (state, payload) => {
      const selectedRecord = {
        ...state.selectedRecord,
        Locations: state.selectedRecord.Locations.map((location) =>
          location.id === payload.id ? payload : location
        )
      };
      return {
        ...state,
        selectedRecord,
        data: state.data.map((item) =>
          item.id === state.selectedRecord.id ? selectedRecord : item
        )
      };
    },
    [CLEAR_NEW_OWNER]: (state) => {
      return {
        ...state,
        newOwner: null
      };
    },
    [SET_OWNER_CREATION_MERCHANT_ID]: (state, payload) => {
      return {
        ...state,
        ownerCreationMerchantId: payload
      };
    },
    [CLEAR_OWNER_CREATION_MERCHANT_ID]: (state) => {
      return {
        ...state,
        ownerCreationMerchantId: null
      };
    },
    [UPDATE_ACTIVE_MERCHANT]: (state) => ({...state, isUpdating: true}),
    [UPDATE_ACTIVE_MERCHANT_SUCCESS]: (state) => ({
      ...state,
      isUpdating: false
    }),
    [SET_TEST_AUTOMATION_RESULT]: (state, payload) => ({
      ...state,
      locationTestJobStatuses: {
        ...state.locationTestJobStatuses,
        [payload.id]: payload.status
      }
    }),
    [SET_TEST_AUTOMATION_JOB_ID]: (state, payload) => ({
      ...state,
      selectedRecord: {
        ...state.selectedRecord,
        Locations: getWithLastTestOrderJobId(
          payload.id,
          state.selectedRecord.Locations,
          payload.jobId
        )
      },
      data: state.data.map((merchant) =>
        merchant.id === state.selectedRecord.id
          ? {
              ...merchant,
              Locations: getWithLastTestOrderJobId(
                payload.id,
                state.selectedRecord.Locations,
                payload.jobId
              )
            }
          : merchant
      )
    }),
    [SET_SALES_PACKAGE_SUCCESS]: (state, payload) => {
      const {
        LocationId,
        body: {SalesPackage, UseSalesPackage}
      } = payload;
      const {data, selectedRecord} = state;

      const updatedRecordLocations = selectedRecord?.Locations?.map((loc) =>
        loc.id === LocationId ? {...loc, SalesPackage, UseSalesPackage} : loc
      );
      const updatedData = data.map((record) =>
        record.id === selectedRecord.id
          ? {...record, Locations: updatedRecordLocations}
          : record
      );
      return {
        ...state,
        data: updatedData
      };
    },
    [SET_GMB_REVIEW_LINK_SUCCESS]: (state, payload) => {
      const {
        LocationId,
        body: {GMBReviewLink, GmbRestaurantUrl, GmbRestaurantPhoneNumber}
      } = payload;
      const {data, selectedRecord} = state;

      const updatedRecordLocations = selectedRecord?.Locations?.map((loc) =>
        loc.id === LocationId
          ? {...loc, GMBReviewLink, GmbRestaurantUrl, GmbRestaurantPhoneNumber}
          : loc
      );
      const updatedData = data.map((record) =>
        record.id === selectedRecord.id
          ? {...record, Locations: updatedRecordLocations}
          : record
      );
      return {
        ...state,
        data: updatedData
      };
    }
  },
  initialState()
);

export default merchantsReducer;
