import {all, fork, takeEvery} from 'redux-saga/effects';
import menuEditorCategorySaga from 'store/menuEditorCategory/saga';
import menuEditorItemSaga from 'store/menuEditorItems/saga';
import menuEditorModifierGroupsSaga from 'store/menuEditorModifierGroups/saga';
import menuEditorModifierSaga from 'store/menuEditorModifier/saga';
import {
  GET_LOCATION_DATA,
  GET_LOCATION_ID,
  GET_USER_DATA
} from 'store/global/user/action';
import userSaga, {
  getUserData as getUserDataSaga,
  getUsersLocations as getUsersLocationsSaga
} from 'store/global/user/saga';
import {configInterseptors} from 'helpers/services/utils/interseptors';
import menuEditorOutStockSaga from 'store/menuEditorOutStock/saga';
import uploadFileSaga from 'store/global/uploadFile/saga';
import reportsSaga from 'store/reports/saga';
import analyticsSalesCountSaga from 'store/analyticsSalesCount/saga';
import analyticsCustomersCountSaga from 'store/analyticsCustomersCount/saga';
import analyticsSalesPerHourSaga from 'store/analyticsSalesPerHour/saga';
import analyticsSalesPerItemSaga from 'store/analyticsSalesPerItem/saga';
import reviewsSaga from 'store/reviews/saga';
import menuEditorSaga from 'store/menuEditor/saga';
import orderListSaga from 'store/orders/orderList/saga';
import menuEditorHiddenSaga from 'store/menuEditorHidden/saga';
import locationSettingsSaga from 'store/locationSettings/saga';
import appVersionSaga from 'store/global/appVersion/saga';
import setupRestaurantSaga from 'store/setupRestaurant/saga';
import menuQueueSaga from 'store/menuQueue/saga';
import usersManagementSaga from 'store/usersManagement/saga';
import AuthSaga from 'store/global/auth/saga';
import merchantsSaga from 'store/merchants/saga';
import AccountBankVerifySaga from 'store/accountVerifyBankAccount/saga';
import accountSaga from 'store/global/account/saga';
import selfCreateAccountSaga from 'store/selfCreateAccount/saga';
import adminUsersSaga from 'store/adminUsers/saga';
import modalsSaga from 'store/global/modals/saga';
import orderDetailsSaga from 'store/orders/orderDetails/saga';
import globalNotificationsSaga from 'store/global/globalNotifications/saga';
import discountSaga from 'store/discounts/saga';
import virtualNumbersSaga from 'store/virtualNumber/saga';
import activeOrdersSaga from 'store/global/orders/saga';
import uberEatsSaga from 'store/uberEats/saga';
import bookADriverCreateSaga from 'store/bookADriver/bookADriverCreate/saga';
import bookADriverListSaga from 'store/bookADriver/bookADriverList/saga';
import orderBADDetailsSaga from 'store/bookADriver/bookADriverDetails/saga';
import activeBookADriverOrdersSaga from 'store/global/bookADriver/saga';
import bookADriverDeliveredOrdersSaga from 'store/global/bookADriverDelivered/saga';
import featureManagementSaga from 'store/featureManagement/saga';
import feedbackSaga from 'store/feedback/saga';
import orderRefundSaga from 'store/orders/orderRefund/saga';
import orderRTBSaga from 'store/realTimeBoard/saga';
import refundsCalculatorSaga from 'store/refundsCalculator/saga';
import hubspotChatSaga from 'store/global/hubspotChat/saga';
import emailMarketingSaga from 'store/emailMarketing/saga';
import insightsGrowsSaga from 'store/insights/growth/saga';
import insightsPerformanceSaga from 'store/insights/performance/saga';
import phoneOrderingSaga from 'store/phoneOrdering/saga';
import orderRTBV2Saga from 'store/realTimeBoardV2/saga';
import insightsControlSaga from 'store/insightsControl/saga';
import orderAdjustSaga from 'store/orders/orderAdjust/saga';
import marketingIntegrationsSaga from 'store/marketingIntegrations/saga';
import findDeliverySaga from 'store/findDelivery/saga';
import connectingStreamSaga from 'store/global/connectingStream/saga';
import cancellationRefundSaga from 'store/global/orders/CancellationRefund/saga';
import adminSudoUsersSaga from 'store/adminSudoUsers/saga';
import flyersSaga from 'store/flyers/saga';

export default function* rootSaga() {
  yield all([
    takeEvery(GET_LOCATION_ID, configInterseptors),
    takeEvery(GET_USER_DATA, getUserDataSaga),
    takeEvery(GET_LOCATION_DATA, getUsersLocationsSaga),
    fork(menuEditorCategorySaga),
    fork(menuEditorItemSaga),
    fork(menuEditorModifierGroupsSaga),
    fork(menuEditorModifierSaga),
    fork(menuEditorOutStockSaga),
    fork(uploadFileSaga),
    fork(analyticsSalesCountSaga),
    fork(analyticsCustomersCountSaga),
    fork(analyticsSalesPerHourSaga),
    fork(analyticsSalesPerItemSaga),
    fork(reviewsSaga),
    fork(userSaga),
    fork(reportsSaga),
    fork(menuEditorSaga),
    fork(locationSettingsSaga),
    fork(appVersionSaga),
    fork(menuEditorHiddenSaga),
    fork(merchantsSaga),
    fork(menuQueueSaga),
    fork(usersManagementSaga),
    fork(setupRestaurantSaga),
    fork(AuthSaga),
    fork(accountSaga),
    fork(AccountBankVerifySaga),
    fork(modalsSaga),
    fork(orderListSaga),
    fork(orderDetailsSaga),
    fork(orderRefundSaga),
    fork(globalNotificationsSaga),
    fork(discountSaga),
    fork(selfCreateAccountSaga),
    fork(adminUsersSaga),
    fork(virtualNumbersSaga),
    fork(activeOrdersSaga),
    fork(uberEatsSaga),
    fork(bookADriverCreateSaga),
    fork(bookADriverListSaga),
    fork(orderBADDetailsSaga),
    fork(activeBookADriverOrdersSaga),
    fork(bookADriverDeliveredOrdersSaga),
    fork(featureManagementSaga),
    fork(feedbackSaga),
    fork(orderRTBSaga),
    fork(refundsCalculatorSaga),
    fork(hubspotChatSaga),
    fork(emailMarketingSaga),
    fork(insightsGrowsSaga),
    fork(insightsPerformanceSaga),
    fork(insightsControlSaga),
    fork(phoneOrderingSaga),
    fork(marketingIntegrationsSaga),
    fork(orderRTBV2Saga),
    fork(findDeliverySaga),
    fork(cancellationRefundSaga),
    fork(adminSudoUsersSaga),
    fork(flyersSaga),
    fork(connectingStreamSaga),
    fork(orderAdjustSaga)
  ]);
}
