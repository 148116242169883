import {createAction} from 'redux-act';

export const GET_SERVICE_MENU_ITEMS = createAction(
  'featureManagement/UPDATE_SERVICE_FEATURE'
);
export const GET_SERVICE_MENU_ITEMS_SUCCESS = createAction(
  'featureManagement/UPDATE_SERVICE_FEATURE_SUCCESS'
);
export const GET_SERVICE_MENU_ITEMS_FAIL = createAction(
  'featureManagement/GET_SERVICE_MENU_ITEMS_FAIL'
);
export const CLEAR_MENU_SERVICE_DATA = createAction(
  'featureManagement/CLEAR_MENU_SERVICE_DATA'
);
export const UPDATE_SERVICE_FEATURE = createAction(
  'featureManagement/UPDATE_SERVICE_FEATURE'
);
export const UPDATE_SERVICE_FEATURE_SUCCESS = createAction(
  'featureManagement/UPDATE_SERVICE_FEATURE_SUCCESS'
);

export const UPDATE_SERVICE_FEATURE_AVAILABILITY = createAction(
  'featureManagement/UPDATE_SERVICE_FEATURE_AVAILABILITY'
);
export const UPDATE_SERVICE_FEATURE_AVAILABILITY_SUCCESS = createAction(
  'featureManagement/UPDATE_SERVICE_FEATURE_AVAILABILITY_SUCCESS'
);
export const SET_MODAL_LOADING = createAction(
  'featureManagement/SET_MODAL_LOADING'
);
export const UPDATE_LOCATION_SERVICE_FEATURE = createAction(
  'featureManagement/UPDATE_SERVICE_FEATURE'
);
export const OPEN_CREATE_FEATURE_MODAL = createAction(
  'featureManagement/OPEN_CREATE_FEATURE_MODAL'
);
export const CREATE_FEATURE = createAction('featureManagement/CREATE_FEATURE');
