import React from 'react';
import {DashboardContent} from 'components/DashboardContent/styles';
import PizzaGif from 'assets/pizza-gif.gif';
import {DashboardInner} from 'components/DashboardInner/styles';
import Subtitle5 from 'components/typography/Subtitle5';

const NoPermission = () => {
  return (
    <DashboardContent className='DashboardContent overflowInherit WithoutDesktopHeader BgGrey'>
      <DashboardInner className='NoPermission withoutShadow paddingLg'>
        <img src={PizzaGif} alt='Pizza Gif' />
        <Subtitle5>
          You don`t have access. Please contact Sauce manager
        </Subtitle5>
      </DashboardInner>
    </DashboardContent>
  );
};

export default NoPermission;
