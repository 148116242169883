const generateAdjustError = (error) => {
  switch (error.code) {
    case 'USER_CHARGE_FAILED':
      return {
        message: `Reason: ${error?.message}`,
        title: 'Could not process order adjustment'
      };
    case 'UPDATING_ORDER_FAILED':
      return {
        message:
          'We processed your adjustment, but it might not be reflected in the order. The user was notified of the additional charge',
        title: 'Something went wrong'
      };
    default:
      return {
        message: null,
        title: 'Something went wrong'
      };
  }
};

export default generateAdjustError;
