import {createReducer} from 'redux-act';
import {SET_LOADING, TOGGLE_REQUEST_MODAL} from 'store/flyers/action';

const initialState = () => ({
  loading: false,
  isOpenRequestModal: false
});

const flyersReducer = createReducer(
  {
    [SET_LOADING]: (state, payload) => ({
      ...state,
      loading: payload
    }),
    [TOGGLE_REQUEST_MODAL]: (state, payload) => ({
      ...state,
      isOpenRequestModal: payload
    })
  },
  initialState()
);

export default flyersReducer;
