import styled from 'styled-components';

export const ConnectingStreamText = styled.div`
  font-size: 16px;
  &.marginLeft40 {
    margin-left: 40px;
  }
`;
export const ConnectingStreamList = styled.ul`
  font-size: 14px;
  margin-left: 30px;
  margin-top: 20px;
  font-weight: bold;
  &.marginLeft70 {
    margin-left: 70px;
  }
`;

export const ConnectingStreamListItem = styled.li`
  margin-bottom: 10px;
  &:last-child {
    margin-bottom: 0;
  }
`;
