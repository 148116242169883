import {createReducer} from 'redux-act';
import {
  CLEAR_MENU_SERVICE_DATA,
  GET_SERVICE_MENU_ITEMS,
  GET_SERVICE_MENU_ITEMS_FAIL,
  GET_SERVICE_MENU_ITEMS_SUCCESS,
  OPEN_CREATE_FEATURE_MODAL,
  SET_MODAL_LOADING,
  UPDATE_SERVICE_FEATURE_AVAILABILITY_SUCCESS,
  UPDATE_SERVICE_FEATURE_SUCCESS
} from './action';

const initialState = () => ({
  data: [],
  loading: false,
  modalLoading: false,
  isCreateFeatureModalOpen: false
});

const featureManagementReducer = createReducer(
  {
    [GET_SERVICE_MENU_ITEMS]: (state) => ({
      ...state,
      loading: true
    }),
    [GET_SERVICE_MENU_ITEMS_FAIL]: (state) => ({
      ...state,
      loading: false
    }),
    [GET_SERVICE_MENU_ITEMS_SUCCESS]: (state, payload) => {
      return {
        ...state,
        data: payload,
        loading: false
      };
    },
    [UPDATE_SERVICE_FEATURE_SUCCESS]: (state, payload) => {
      const {feature, status} = payload;
      const updateItems = (items) =>
        items?.map((item) =>
          item.id === feature ? {...item, enabled: status === 'enable'} : item
        );

      return {
        ...state,
        data: {
          dashboardItems: updateItems(state.data?.dashboardItems)
        }
      };
    },
    [UPDATE_SERVICE_FEATURE_AVAILABILITY_SUCCESS]: (state, payload) => {
      const {feature, isNewFeature} = payload;
      const updateItems = (items) =>
        items?.map((item) =>
          item.id === feature ? {...item, isNewFeature} : item
        );

      return {
        ...state,
        data: {
          dashboardItems: updateItems(state.data?.dashboardItems)
        }
      };
    },
    [SET_MODAL_LOADING]: (state, payload) => ({
      ...state,
      modalLoading: payload
    }),
    [OPEN_CREATE_FEATURE_MODAL]: (state, payload) => ({
      ...state,
      isCreateFeatureModalOpen: payload
    }),
    [CLEAR_MENU_SERVICE_DATA]: () => ({
      ...initialState()
    })
  },
  initialState()
);

export default featureManagementReducer;
