import {
  put,
  call,
  take,
  race,
  delay,
  cancel,
  select,
  takeLatest,
  takeEvery
} from 'redux-saga/effects';
import {
  RUN_GET_ACTIVE_ORDERS_JOB,
  SET_ACTIVE_ORDERS,
  SET_NEW_ORDERS,
  CHANGE_STATUS_ACTIVE,
  CHANGE_STATUS_ACTIVE_SUCCESS,
  STOP_GET_ACTIVE_ORDERS_JOB
} from './action';
import {getActiveOrdersApi} from '../../../helpers/services/api/orders';
import {
  ORDER,
  ORDER_CANCELLATION_STATUS,
  ORDER_STATUS
} from '../../../helpers/constants/orders';
import {changeStatus} from '../../orders/orderList/saga';
import {getStorageToken} from '../../../helpers/localStorage/authStorage';
import {GET_UBEREATS_DATA} from '../../uberEats/actions';
// import Logger from '../../../services/logger/Logger';
// import loggerClient from '../../../services/logger/ClientLogger';
import {errorLog, infoLog} from '../../../services/logger/new-logger';
import {OPEN_CANCELLATION_REFUND_MODAL} from './CancellationRefund/action';
import {
  OPEN_CANCELLATION_CONFIRMATION_MODAL,
  SET_LOADING_CANCELLATION
} from '../modals/action';

const GET_NEW_ORDERS_INTERVAL = 30000;

const currentActiveOrders = ({activeOrdersReducer}) =>
  activeOrdersReducer.activeOrders;

const userState = ({user}) => user;

export function* getActiveOrdersJob(stopNotificationSound) {
  while (true) {
    try {
      if (stopNotificationSound) {
        stopNotificationSound();
      }
      const {data, status} = yield getActiveOrdersApi({silent: true});

      const currentOrders = yield select(currentActiveOrders);
      const {Timezone} = yield select((state) => state.user.activeLocation);
      const locationId = yield select(
        (state) => state.user.activeLocation?._id
      );
      const merchantId = yield select(
        (state) => state.user.userData?.merchant_id
      );

      const BYOCconnected = yield select(
        (state) =>
          state.uberEatsReducer.uberEatsData?.activationSettings?.byoc
            ?.isActivated
      );

      yield put(SET_ACTIVE_ORDERS({data: data || [], Timezone}));

      // Logger.logSuccess(
      //   'ORDERS_POLLING_FRONTEND_SUCCESS',
      //   {orders: data?.map((order) => order?.id)},
      //   status
      // );

      infoLog(
        'ORDERS_POLLING_FRONTEND_SUCCESS',
        {
          Orders: data?.map((order) => order?.id)
        },
        yield select(userState)
      );

      const diff = currentOrders
        ? data?.filter(
            (newOrder) =>
              !currentOrders?.filter((order) => order.id === newOrder.id)[0] &&
              (!newOrder[ORDER.ORDER_STATUS] ||
                newOrder[ORDER.ORDER_STATUS] === ORDER_STATUS.NEW)
          )
        : [];

      if (diff.length > 0) {
        yield put(SET_NEW_ORDERS(diff));

        // TODO remove or fix in feature GROW-84
        // const uberOrder = diff.find((order) =>
        //   order.Platform.toLowerCase().includes('uber')
        // );
        // if (uberOrder && !BYOCconnected) {
        //   yield put(GET_UBEREATS_DATA({merchantId, locationId}));
        // }
      }
    } catch (error) {
      // Logger.logError('ORDERS_POLLING_FRONTEND_ERROR', {}, 500, error);

      errorLog(
        'ORDERS_POLLING_FRONTEND_ERROR',
        {},
        error,
        yield select(userState)
      );

      // Do nothing for now cz it's a bg job
    }

    yield delay(GET_NEW_ORDERS_INTERVAL);
  }
}

function* runGetActiveOrdersJob({payload}) {
  try {
    const isOnline = yield select(
      (state) => state.globalNotificationsReducer?.isNetworkOnline
    );
    const userData = yield select((state) => state.user.userData);
    const locationId = yield select((state) => state.user.activeLocation?._id);
    const {token} = getStorageToken();

    if (
      !!window.AndroidDashboard ||
      !isOnline ||
      !token ||
      !userData.role ||
      !locationId ||
      userData.role === 'sudo'
    ) {
      return;
    }

    const {stop} = yield race({
      job: call(() => getActiveOrdersJob(payload?.onPollAction)),
      stop: take(STOP_GET_ACTIVE_ORDERS_JOB)
    });

    if (stop) {
      yield cancel();
    }
  } catch (error) {
    // Logger.logError('ORDERS_POLLING_FRONTEND_ERROR', {}, 500, error);
    errorLog(
      'ORDERS_POLLING_FRONTEND_ERROR',
      {},
      error,
      yield select(userState)
    );
    console.log('SAGA ERROR', error);
  }
}

export function* changeStatusActiveOrder({
  payload: {id, status, previousStatus},
  orderId = null
}) {
  try {
    yield put(SET_LOADING_CANCELLATION(true));
    const data = yield changeStatus({payload: {id, status, previousStatus}});

    if (data && data[ORDER.ID]) {
      if (data[ORDER.ORDER_STATUS] === 'Completed') {
        yield put(CHANGE_STATUS_ACTIVE_SUCCESS({[ORDER.ID]: data[ORDER.ID]}));
      }
      if (data[ORDER.ORDER_STATUS] === ORDER_STATUS.CANCELLED) {
        yield put(
          OPEN_CANCELLATION_CONFIRMATION_MODAL({
            isOpen: false,
            info: null,
            source: null,
            deliveryId: null
          })
        );
        yield put(
          OPEN_CANCELLATION_REFUND_MODAL({
            isOpenModal: true,
            invoiceId:
              !data[ORDER.REFUND] ||
              !data[ORDER.REFUND]?.find((el) => el.type === 'Full')
                ? data[ORDER.INVOICE_ID]
                : null,
            showCannotDeliveryText:
              data[ORDER.ORDER_CANCELLATION_STATUS] &&
              data[ORDER.ORDER_CANCELLATION_STATUS] !==
                ORDER_CANCELLATION_STATUS.CANCELLED_SUCCESSFULLY,
            orderId
          })
        );
        yield put(CHANGE_STATUS_ACTIVE_SUCCESS({[ORDER.ID]: data[ORDER.ID]}));
      } else {
        yield put(
          CHANGE_STATUS_ACTIVE_SUCCESS({
            [ORDER.ORDER_STATUS]: data[ORDER.ORDER_STATUS],
            [ORDER.ID]: data[ORDER.ID]
          })
        );
      }
    }
    yield put(SET_LOADING_CANCELLATION(false));
    return data[ORDER.ORDER_STATUS];
  } catch (error) {
    yield put(SET_LOADING_CANCELLATION(false));
    console.log('SAGA ERROR', error);
    return false;
  }
}

function* activeOrdersSaga() {
  yield takeLatest(RUN_GET_ACTIVE_ORDERS_JOB, runGetActiveOrdersJob);
  yield takeEvery(CHANGE_STATUS_ACTIVE, changeStatusActiveOrder);
}

export default activeOrdersSaga;
