import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {ReviewsInner} from '../menuQueue/MenuQueueContainerStyle';
import Headline from '../../../../components/headline2/HeadlineComponent';
import FeatureManagementTable from './FeatureManagementTable';
import {FeatureManagementHeadCells} from '../../../../helpers/constants/Table';
import {
  CLEAR_MENU_SERVICE_DATA,
  GET_SERVICE_MENU_ITEMS,
  OPEN_CREATE_FEATURE_MODAL,
  UPDATE_SERVICE_FEATURE,
  UPDATE_SERVICE_FEATURE_AVAILABILITY
} from '../../../../store/featureManagement/action';
import WarningModal from './Modals/WarningModal';
import {DashboardContent} from '../../../../components/DashboardContent/styles';
import {ButtonPrimary} from '../../../../components/buttons';
import {DashboardHeader} from '../../../../components/DashboardHeader/styles';
import IconAdd from '../../../../assets/IconAdd';
import CreateFeatureModal from './Modals/CreateFeatureModal';

const FeatureManagement = () => {
  const dispatch = useDispatch();
  const [statusWarningModal, setStatusWarningModal] = useState(false);
  const [availabilityWarningModal, setAvailabilityWarningModal] = useState(
    false
  );

  const [modalModel, setModalModel] = useState({});

  const {data, isCreateFeatureModalOpen} = useSelector(
    (state) => state.featureManagementReducer
  );
  const loading = useSelector(
    (state) => state.featureManagementReducer.loading
  );

  useEffect(() => {
    dispatch(GET_SERVICE_MENU_ITEMS());
    return () => dispatch(CLEAR_MENU_SERVICE_DATA());
  }, []);

  const handleWarningModal = (row, modalFunc) => {
    modalFunc((prevState) => !prevState);
    setModalModel(row);
  };

  const onModalSubmit = (row, submitAction) => {
    dispatch(submitAction);
  };

  return (
    <DashboardContent className='DashboardContentWithoutHeader'>
      <DashboardHeader>
        <Headline label='Feature Management' />
        <ButtonPrimary
          className='ButtonAdd ButtonMedium'
          onClick={() => {
            dispatch(OPEN_CREATE_FEATURE_MODAL(true));
          }}
        >
          <IconAdd />
          <span>Add new</span>
        </ButtonPrimary>
      </DashboardHeader>
      <ReviewsInner>
        <FeatureManagementTable
          loading={loading}
          headCells={FeatureManagementHeadCells}
          items={data?.dashboardItems}
          onStatusHandle={(row) =>
            handleWarningModal(row, setStatusWarningModal)
          }
          onStatusAvailabilityHandle={(row) =>
            handleWarningModal(row, setAvailabilityWarningModal)
          }
        />
        {statusWarningModal && (
          <WarningModal
            onClose={() => handleWarningModal({}, setStatusWarningModal)}
            row={modalModel}
            dialogText={
              <>
                Are you sure you want to{' '}
                {modalModel?.enabled ? 'disable' : 'enable'} this feature for{' '}
                <strong>ALL</strong> locations?
              </>
            }
            submitButtonText={modalModel?.enabled ? 'Disable' : 'Enable'}
            onSubmit={() =>
              onModalSubmit(
                modalModel,
                UPDATE_SERVICE_FEATURE({
                  feature: modalModel?.id,
                  status: modalModel?.enabled ? 'disable' : 'enable',
                  callBack: () => handleWarningModal({}, setStatusWarningModal)
                })
              )
            }
          />
        )}

        {availabilityWarningModal && (
          <WarningModal
            onClose={() => handleWarningModal({}, setAvailabilityWarningModal)}
            row={modalModel}
            dialogText={
              <>
                Are you sure you want to{' '}
                {modalModel?.isNewFeature
                  ? 'remove New mark from this feature'
                  : 'add New mark for this feature'}{' '}
                for <strong>ALL</strong> locations?
              </>
            }
            submitButtonText={modalModel?.isNewFeature ? 'Remove' : 'Add'}
            onSubmit={() =>
              onModalSubmit(
                modalModel,
                UPDATE_SERVICE_FEATURE_AVAILABILITY({
                  feature: modalModel?.id,
                  isNewFeature: !modalModel?.isNewFeature,
                  callBack: () =>
                    handleWarningModal({}, setAvailabilityWarningModal)
                })
              )
            }
          />
        )}
        <CreateFeatureModal isOpen={isCreateFeatureModalOpen} />
      </ReviewsInner>
    </DashboardContent>
  );
};

export default FeatureManagement;
