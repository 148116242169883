import {createReducer} from 'redux-act';
import {
  CLEAR_ACTIVE_ORDERS,
  CLEAR_NEW_ORDERS,
  RUN_GET_ACTIVE_ORDERS_JOB,
  SET_ACTIVE_ORDERS,
  SET_NEW_ORDERS,
  CHANGE_STATUS_ACTIVE_SUCCESS,
  CHANGE_STATUS_ACTIVE,
  CHANGE_CURRENT_LOCATION_TIMEZONE,
  SET_ORDERS_SORTING,
  CHANGE_REFUNDS_ACTIVE,
  SET_ORDERS_SEARCH
} from './action';
import {calculateOverdueAndFuture} from '../../../helpers/functionUtils/ordersUtils';
import {ORDER} from '../../../helpers/constants/orders';
import {CHANGE_ORDER_INFO} from '../../orders/orderList/action';

const initialSorting = () => ({
  sort: 'desc',
  sortBy: 'eta_time_for_merchant'
});

const initialState = () => ({
  activeOrders: undefined,
  newOrders: [],
  activeOrdersCount: 0,
  sorting: initialSorting(),
  changeStatusLoading: null,
  search: '',
  filteredOrders: []
});

const activeOrdersReducer = createReducer(
  {
    [SET_ACTIVE_ORDERS]: (state, payload) => {
      const orders = payload?.data.map((order) =>
        calculateOverdueAndFuture({Timezone: payload?.Timezone, ...order})
      );
      return {
        ...state,
        activeOrders: orders,
        activeOrdersCount: orders.length,
        loading: false
      };
    },
    [CHANGE_STATUS_ACTIVE]: (state, payload) => ({
      ...state,
      changeStatusLoading: payload.id
    }),
    [CHANGE_STATUS_ACTIVE_SUCCESS]: (state, payload) => {
      const cloneOrders = [...state.activeOrders];
      const index = cloneOrders.findIndex(
        (order) => order[ORDER.ID] === payload[ORDER.ID]
      );

      if (index !== -1) {
        if (payload[ORDER.ORDER_STATUS]) {
          cloneOrders[index][ORDER.ORDER_STATUS] = payload[ORDER.ORDER_STATUS];
        } else {
          cloneOrders.splice(index, 1);
        }
      }

      return {
        ...state,
        activeOrders: cloneOrders,
        changeStatusLoading: null
      };
    },
    [CHANGE_REFUNDS_ACTIVE]: (state, payload) => {
      const cloneOrders = [...state.activeOrders];
      const index = cloneOrders.findIndex(
        (order) => order[ORDER.ID] === payload[ORDER.ID]
      );

      if (index !== -1) {
        cloneOrders[index][ORDER.REFUND] = payload[ORDER.REFUND];
      }

      return {
        ...state,
        activeOrders: cloneOrders,
        changeStatusLoading: null
      };
    },
    [RUN_GET_ACTIVE_ORDERS_JOB]: (state) => ({
      ...state,
      loading: !state.activeOrders
    }),
    [CLEAR_ACTIVE_ORDERS]: (state) => ({
      ...state,
      activeOrders: undefined,
      activeOrdersCount: 0,
      filteredOrders: [],
      search: ''
    }),
    [SET_NEW_ORDERS]: (state, payload) => ({
      ...state,
      newOrders: payload
    }),
    [SET_ORDERS_SORTING]: (state, payload) => {
      return {
        ...state,
        sorting: {
          ...state.sorting,
          ...payload
        }
      };
    },
    [CLEAR_NEW_ORDERS]: (state) => ({
      ...state,
      newOrders: []
    }),
    [CHANGE_CURRENT_LOCATION_TIMEZONE]: (state, payload) => ({
      ...state,
      currentLocationTimezone: payload
    }),
    [CHANGE_ORDER_INFO]: (state, payload) => {
      const updatedOrders = state?.activeOrders?.map((order) =>
        order.id === payload.id
          ? {...order, InternalNotes: payload.note}
          : order
      );
      return {
        ...state,
        activeOrders: updatedOrders
      };
    },
    [SET_ORDERS_SEARCH]: (state, payload) => {
      let updatedOrders = state?.activeOrders;
      if (payload?.trim()) {
        updatedOrders = updatedOrders?.filter((order) =>
          order.userName
            ?.toLowerCase()
            ?.includes(payload?.trim()?.toLowerCase())
        );
      }
      return {
        ...state,
        search: payload,
        filteredOrders: updatedOrders
      };
    }
  },

  initialState()
);

export default activeOrdersReducer;
