import React, {useState, useEffect, useRef} from 'react';
import cn from 'classnames';
import {useDispatch, useSelector} from 'react-redux';
import {DashboardInner} from '../../../../../components/DashboardInner/styles';
import {ButtonPrimary} from '../../../../../components/buttons';
import {DashboardField, DashboardFields} from '../../../dashboard/styles';
import Field from '../../../../../components/Field/styles';
import ButtonClear from '../../../../../components/buttons/ButtonClear/styles';
import {
  ErrorSign,
  ErrorSignWrapper
} from '../../../../../components/errorSign/errorSign';
import {FieldLabel} from '../../../../../components/FieldLabel/styles';
import {
  RC_CLEAR_ALL_CALCULATOR_DATA,
  RC_GET_ORDER,
  RC_ORDER_INVOICE_ERROR
} from '../../../../../store/refundsCalculator/action';

const RCSearch = () => {
  const dispatch = useDispatch();
  const searchInput = useRef(null);
  const [valueField, setValueField] = useState('');
  const errorMessage = useSelector(
    (state) => state.refundsCalculator.invoiceIdError
  );
  const {orderData, refunds} = useSelector((state) => state.refundsCalculator);

  useEffect(() => {
    if (!orderData?.id && searchInput?.current) {
      searchInput?.current?.focus();
    }
  }, [orderData?.id]);

  useEffect(() => {
    if (!valueField) {
      dispatch(RC_ORDER_INVOICE_ERROR(null));
    }
  }, [valueField]);

  return (
    <DashboardInner className='RefundsCalculator RCSearch withoutShadow'>
      <DashboardFields className='DashboardFields alignItemsBottom marginMinus'>
        <DashboardField className='wAutoSpace'>
          <FieldLabel className='mBottomMd'>Invoice ID</FieldLabel>
          <Field className='SearchPanelInput withClear'>
            <input
              className={cn(
                'FieldSearch Lg Outline BgGrey colorGrey textUppercase',
                {
                  error: !!errorMessage && (!refunds || !refunds?.length),
                  notEmpty: !!valueField
                }
              )}
              type='search'
              placeholder='Search by 𝗜𝗻𝘃𝗼𝗶𝗰𝗲 𝗜𝗗'
              onChange={(e) => {
                setValueField(e.target.value);
              }}
              id='searchByInvoiceID'
              value={valueField}
              disabled={orderData?.id}
              ref={searchInput}
            />
          </Field>
          <ErrorSignWrapper>
            <ErrorSign className='colorRed'>
              {errorMessage && (!refunds || !refunds?.length) && errorMessage}
            </ErrorSign>
          </ErrorSignWrapper>
        </DashboardField>
        <DashboardField className='wAuto'>
          {orderData?.id ? (
            <ButtonPrimary
              className='ButtonShowOrder'
              onClick={() => {
                setValueField('');
                dispatch(RC_CLEAR_ALL_CALCULATOR_DATA());
              }}
            >
              <span>Clear Order</span>
            </ButtonPrimary>
          ) : (
            <ButtonPrimary
              className='ButtonShowOrder'
              onClick={() => {
                if (valueField) {
                  dispatch(RC_GET_ORDER(valueField));
                }
              }}
            >
              <span>Show Order</span>
            </ButtonPrimary>
          )}
        </DashboardField>
      </DashboardFields>
    </DashboardInner>
  );
};

export default RCSearch;
