import React from 'react';
import {
  Link,
  Redirect,
  Route,
  Switch,
  useLocation,
  useRouteMatch
} from 'react-router-dom';
import Tab from '@material-ui/core/Tab';
import {Tabs} from '@material-ui/core';
import MerchantsUrls from 'routes/App/merchants/MerchantsUrls';
import ActiveUsers from 'routes/App/merchants/ActiveUsers';
import {DashboardTabs} from 'routes/App/dashboard/styles';
import {DashboardContent} from 'components/DashboardContent/styles';

export default function MerchantsRoutes() {
  const {path} = useRouteMatch();
  const {pathname} = useLocation();

  return (
    <DashboardContent className='DashboardContentWithoutHeader DashboardContent'>
      <DashboardTabs className='TabsMenuEditor'>
        <Tabs value={pathname}>
          <Tab
            label='New'
            value='/managers/merchants/new'
            component={Link}
            to='/managers/merchants/new'
          />
          <Tab
            label='Active'
            value='/managers/merchants/active'
            component={Link}
            to='/managers/merchants/active'
          />
        </Tabs>
      </DashboardTabs>
      <br />
      <Switch>
        <Route path='/managers/merchants/active' component={ActiveUsers} />
        <Route path='/managers/merchants/new' component={MerchantsUrls} />
        <Route
          path='/managers/merchants'
          render={() => <Redirect to='/managers/merchants/new' />}
        />
      </Switch>
    </DashboardContent>
  );
}
