import {createAction} from 'redux-act';

export const GET_MERCHANTS = createAction('connectingStream/GET_MERCHANTS');

export const GET_MERCHANTS_SUCCESS = createAction(
  'connectingStream/GET_MERCHANTS_SUCCESS'
);

export const CONNECT_MERCHANT_TO_STREAM = createAction(
  'connectingStream/CONNECT_MERCHANT_TO_STREAM'
);
