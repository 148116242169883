import React, {useEffect, useState} from 'react';
import {Dialog} from '@material-ui/core';
import PropTypes from 'prop-types';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import {useDispatch, useSelector} from 'react-redux';
import {useFormik} from 'formik';
import {DashboardField, DialogBody} from 'routes/App/dashboard/styles';
import DialogFields from 'components/DialogFields/styles';
import DialogField from 'components/DialogField/styles';
import {DialogSubTitle} from 'components/DialogSubTitle/styles';
import ButtonPrimary from 'components/buttons/ButtonPrimary/styles';
import {
  ACTIVATE_LOCATION,
  CHANGE_LOCATION,
  GET_FEATURE_FLAGS,
  GET_UNASSIGNED_MENU_QUEUE,
  RUN_CHECK_AUTOMATION_TEST_RESULTS_JOB,
  SET_GMB_REVIEW_LINK,
  SET_SALES_PACKAGE,
  STOP_CHECK_AUTOMATION_TEST_RESULTS_JOB
} from 'store/merchants/action';
import FieldInput from 'routes/App/merchants/ActiveUsers/components/FieldInput';
import {FieldLabel} from 'components/FieldLabel/styles';
import {ButtonOutline} from 'components/buttons';
import {FlexContainer, FlexItem} from 'components/layout';
import DialogContainer from 'components/DialogContainer/styles';
import DialogClose from 'components/DialogClose/styles';
import DialogTitle from 'components/DialogTitle/styles';
import {Body1} from 'components/typography';
import TestOrderPanel from 'routes/App/merchants/ActiveUsers/modals/components/TestOrderPanel';
import AutocompleteField from 'components/AutocompleteField/AutocompleteField';
import {
  CLEAR_MENU_SERVICE_DATA,
  GET_SERVICE_MENU_ITEMS,
  UPDATE_LOCATION_SERVICE_FEATURE
} from 'store/featureManagement/action';
import {
  HIDDEN_FEATURE_FLAGS,
  SALES_PACKAGES
} from 'helpers/constants/merchants';
import CheckboxS2E from 'components/Checkbox';
import InfoModal from 'components/ModalBox/InfoModal';
import {
  STRIPE_ACCOUNT_RESTRICTION_NAMES,
  stripeAccRestrictedFeatures
} from 'helpers/constants/restrictions';
import Subtitle14px from 'components/typography/Subtitle14px';
import SwitchS2E from 'components/Switch';

export default function EditActiveMerchant({onClose}) {
  const [apiCallLoader, setApiCallLoader] = useState(false);
  const [apiCallLoaderGMB, setApiCallLoaderGMB] = useState(false);
  const [warningModalOpen, setWarningModalOpen] = useState(false);
  const [advanceMode, setAdvanceMode] = useState(false);
  const {
    selectedRecord,
    webConfig,
    menuConfig,
    dashboardFeatureFlags,
    advanceDashboardFeatureFlags
  } = useSelector((state) => state.merchantsReducer);

  const updateFeaturesLoading = useSelector(
    (state) => state.featureManagementReducer.modalLoading
  );

  const dispatch = useDispatch();
  const {AllowedFeatures} = useSelector((state) => state.user.userData);

  const formik = useFormik({
    initialValues: selectedRecord,
    enableReinitialize: true
  });

  useEffect(() => {
    dispatch(GET_UNASSIGNED_MENU_QUEUE());
    // TODO hotfix GROW-278
    // dispatch(RUN_CHECK_AUTOMATION_TEST_RESULTS_JOB());
    dispatch(GET_FEATURE_FLAGS());

    return () => {
      // TODO hotfix GROW-278
      // dispatch(STOP_CHECK_AUTOMATION_TEST_RESULTS_JOB());
      dispatch(CLEAR_MENU_SERVICE_DATA());
    };
  }, []);

  const saveLocationFeatures = (featuresArray, locationId, locationName) => {
    dispatch(
      UPDATE_LOCATION_SERVICE_FEATURE({
        id: locationId,
        data: featuresArray,
        locationName
      })
    );
  };

  const saveSalesPackage = (
    SalesPackage,
    UseSalesPackage,
    IsHybridMmPricing,
    LocationId
  ) => {
    dispatch(
      SET_SALES_PACKAGE({
        LocationId,
        body: {
          SalesPackage,
          UseSalesPackage,
          IsHybridMmPricing
        },
        preCallBack: () => setApiCallLoader(true),
        postCallBack: () => setApiCallLoader(false)
      })
    );
  };

  const saveGMBReviewLink = (
    GMBReviewLink,
    GmbRestaurantUrl,
    GmbRestaurantPhoneNumber,
    LocationId
  ) => {
    dispatch(
      SET_GMB_REVIEW_LINK({
        LocationId,
        body: {
          GmbRestaurantUrl,
          GmbRestaurantPhoneNumber,
          GMBReviewLink
        },
        preCallBack: () => setApiCallLoaderGMB(true),
        postCallBack: () => setApiCallLoaderGMB(false)
      })
    );
  };
  return (
    <Dialog open scroll='body' onClose={onClose}>
      <DialogContainer className='DialogMd'>
        <DialogClose onClick={onClose} />
        <DialogTitle className='marginBottom2l'>
          Editing {selectedRecord.MerchantName} Locations
        </DialogTitle>
        {AllowedFeatures?.includes('advance_mode') && (
          <DashboardField className='w100 padding0 FeatureFlagsAdvanceMode'>
            <SwitchS2E
              labelPlacement='end'
              label='Advanced mode'
              checked={advanceMode}
              onChange={(e) => {
                setAdvanceMode(e.target.checked);
              }}
              name='advanceMode'
              className='red endPlacement'
            />
          </DashboardField>
        )}
        <DialogBody>
          {formik.values.Locations?.map((loc, index) => {
            const generatedLink = loc.GeneratedLink || {};
            const GmbRestaurantUrl = loc.GmbRestaurantUrl || '';
            const GmbRestaurantPhoneNumber = loc.GmbRestaurantPhoneNumber || '';
            const GMBReviewLink = loc.GMBReviewLink || '';
            const linkErrors = formik.errors.Locations?.[index]?.GeneratedLink;
            const linkTouched =
              formik.touched?.Locations?.[index].GeneratedLink;
            const locationFullName = loc.FullName;
            const locationId = loc.id;

            return (
              <>
                <br />
                <DialogSubTitle>
                  {`${index + 1}. ${locationFullName}`}
                </DialogSubTitle>
                <Subtitle14px className='mB9'>
                  Allowed dashboard feature flags
                </Subtitle14px>
                <FlexContainer
                  justifyContent='space-between'
                  className='align-center'
                >
                  <FlexItem flex={1} className='marginRightXs'>
                    {Array.isArray(advanceDashboardFeatureFlags) && loc && (
                      <>
                        <AutocompleteField
                          multiple
                          disableCloseOnSelect
                          value={
                            advanceMode
                              ? loc?.AllowedServiceFeatures || []
                              : loc?.AllowedServiceFeatures?.filter(
                                  (item) => !HIDDEN_FEATURE_FLAGS.includes(item)
                                ) || []
                          }
                          options={
                            advanceMode
                              ? advanceDashboardFeatureFlags
                              : dashboardFeatureFlags
                          }
                          optionLabelName='id'
                          name={`Locations[${index}].AllowedServiceFeatures`}
                          onChange={(e, value) => {
                            const ActiveFeatures =
                              loc?.AllowedServiceFeatures || [];
                            const addedFeature = value.filter(
                              (item) => !ActiveFeatures.includes(item)
                            )[0];
                            let updatedValue = value;

                            if (!advanceMode) {
                              const hiddenFlags = ActiveFeatures.filter(
                                (item) => HIDDEN_FEATURE_FLAGS.includes(item)
                              );
                              updatedValue = [
                                ...new Set([...hiddenFlags, ...value])
                              ];
                            }

                            if (
                              addedFeature &&
                              loc?.StripeAccountType ===
                                STRIPE_ACCOUNT_RESTRICTION_NAMES.CONNECTED_STANDARD &&
                              stripeAccRestrictedFeatures.includes(addedFeature)
                            ) {
                              setWarningModalOpen(true);
                            }

                            formik.setFieldValue(
                              `Locations[${index}].AllowedServiceFeatures`,
                              updatedValue
                            );
                          }}
                        />
                      </>
                    )}
                  </FlexItem>
                  <FlexItem>
                    {Array.isArray(advanceDashboardFeatureFlags) && (
                      <ButtonPrimary
                        className={
                          updateFeaturesLoading
                            ? 'ButtonSm disabled'
                            : 'ButtonSm'
                        }
                        type='button'
                        onClick={() => {
                          saveLocationFeatures(
                            loc?.AllowedServiceFeatures || [],
                            loc?.id,
                            loc?.FullName
                          );
                        }}
                      >
                        <span>Save</span>
                      </ButtonPrimary>
                    )}
                  </FlexItem>
                </FlexContainer>

                <Subtitle14px className='mT19'>GMB info</Subtitle14px>
                <FlexContainer
                  justifyContent='space-between'
                  className='align-center marginTopXs'
                >
                  <FlexContainer className='flexColumn w100'>
                    <FlexItem flex={1}>
                      <FieldLabel>GMB restaurant URL</FieldLabel>
                      <FieldInput
                        name={`Locations[${index}].GmbRestaurantUrl`}
                        value={GmbRestaurantUrl}
                        // errorText={
                        //   linkErrors?.url && linkTouched?.url
                        //       ? linkErrors?.url
                        //       : null
                        // }
                        onChange={formik.handleChange}
                      />
                    </FlexItem>
                    <FlexItem className='marginTopXs' flex={1}>
                      <FieldLabel>GMB restaurant phone</FieldLabel>
                      <FieldInput
                        name={`Locations[${index}].GmbRestaurantPhoneNumber`}
                        value={GmbRestaurantPhoneNumber}
                        // errorText={
                        //   linkErrors?.url && linkTouched?.url
                        //       ? linkErrors?.url
                        //       : null
                        // }
                        onChange={formik.handleChange}
                      />
                    </FlexItem>
                    <FlexItem className='marginTopXs' flex={1}>
                      <FieldLabel>GMB ReviewLink</FieldLabel>
                      <FieldInput
                        name={`Locations[${index}].GMBReviewLink`}
                        value={GMBReviewLink}
                        // errorText={
                        //   linkErrors?.url && linkTouched?.url
                        //       ? linkErrors?.url
                        //       : null
                        // }
                        onChange={formik.handleChange}
                      />
                    </FlexItem>
                  </FlexContainer>

                  <FlexItem className='alignSelfCenter marginTopSm marginLeftXs'>
                    <ButtonPrimary
                      className={
                        apiCallLoaderGMB ? 'ButtonSm disabled' : 'ButtonSm'
                      }
                      type='button'
                      onClick={() => {
                        saveGMBReviewLink(
                          loc?.GMBReviewLink,
                          loc?.GmbRestaurantUrl,
                          loc?.GmbRestaurantPhoneNumber,
                          loc.id
                        );
                      }}
                    >
                      <span>Save</span>
                    </ButtonPrimary>
                  </FlexItem>
                </FlexContainer>

                <Subtitle14px className='mT19'>Sales package info</Subtitle14px>
                <FlexContainer
                  justifyContent='space-between'
                  className='marginTopXs'
                >
                  <FlexContainer className='flexColumn w100 marginRightXs'>
                    <FlexItem flex={1} className='Say2EatSelect'>
                      <FieldLabel>Sales Package</FieldLabel>
                      <FormControl>
                        <Select
                          inputProps={{'aria-label': 'value'}}
                          onChange={formik.handleChange}
                          value={loc.SalesPackage}
                          name={`Locations[${index}].SalesPackage`}
                        >
                          {SALES_PACKAGES.map((item) => (
                            <MenuItem value={item.value} key={item.id}>
                              {item.packageName}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </FlexItem>
                    <FlexItem
                      flex={1}
                      className='marginRightXs Say2EatSelect marginTopXs'
                    >
                      <FieldLabel>Sales Package</FieldLabel>
                      <FormControl>
                        <CheckboxS2E
                          onChange={(e) => {
                            formik.setFieldValue(
                              `Locations[${index}].UseSalesPackage`,
                              e.target.checked
                            );
                            if (!e.target.checked) {
                              formik.setFieldValue(
                                `Locations[${index}].IsHybridMmPricing`,
                                false
                              );
                            }
                          }}
                          name={`Locations[${index}].UseSalesPackage`}
                          checked={loc?.UseSalesPackage}
                          label='Enable Sales Package'
                        />
                      </FormControl>
                      <FormControl>
                        <CheckboxS2E
                          onChange={formik.handleChange}
                          name={`Locations[${index}].IsHybridMmPricing`}
                          checked={!!loc?.IsHybridMmPricing}
                          disabled={!loc.UseSalesPackage}
                          label='Enable cheap items (hybrid) model'
                        />
                      </FormControl>
                    </FlexItem>
                  </FlexContainer>

                  <FlexItem className='marginTopLg2'>
                    <ButtonPrimary
                      className={
                        apiCallLoader ? 'ButtonSm disabled' : 'ButtonSm'
                      }
                      type='button'
                      onClick={() => {
                        saveSalesPackage(
                          loc?.SalesPackage,
                          loc?.UseSalesPackage,
                          loc?.IsHybridMmPricing,
                          loc.id
                        );
                      }}
                    >
                      <span>Save</span>
                    </ButtonPrimary>
                  </FlexItem>
                </FlexContainer>

                <Subtitle14px className='mT19'>Ordering info</Subtitle14px>

                <DialogFields className='mBottom20 verticalBottom'>
                  <DialogField className='w100 wAuto'>
                    <FieldInput
                      disabled
                      name={
                        generatedLink?.mask
                          ? `Locations[${index}].GeneratedLink.mask`
                          : `Locations[${index}].GeneratedLink.short`
                      }
                      label='Short Url'
                      value={
                        generatedLink?.mask
                          ? generatedLink.mask
                          : generatedLink?.short
                      }
                      errorText={
                        // eslint-disable-next-line no-nested-ternary
                        generatedLink?.mask
                          ? linkErrors?.mask && linkTouched?.mask
                            ? linkErrors?.mask
                            : null
                          : linkErrors?.short && linkTouched?.short
                          ? linkErrors?.short
                          : null
                      }
                      onChange={formik.handleChange}
                    />
                  </DialogField>
                  <DialogField className='w100'>
                    <FieldInput
                      name={`Locations[${index}].GeneratedLink.url`}
                      label='S2E Url'
                      value={generatedLink?.url}
                      errorText={
                        linkErrors?.url && linkTouched?.url
                          ? linkErrors?.url
                          : null
                      }
                      onChange={formik.handleChange}
                    />
                  </DialogField>

                  {loc.WixWebOrderingConfigId ? (
                    <DialogField className='w50'>
                      <FieldInput
                        disabled
                        label='Web Config'
                        value={loc.WixWebOrderingConfigId}
                      />
                    </DialogField>
                  ) : (
                    <DialogField className='w50 Say2EatSelect'>
                      <FieldLabel>Web Config</FieldLabel>
                      <FormControl>
                        <Select
                          inputProps={{'aria-label': 'value'}}
                          onChange={formik.handleChange}
                          value={loc.WixWebOrderingConfigId}
                          name={`Locations[${index}].WixWebOrderingConfigId`}
                        >
                          {webConfig.map((item) => (
                            <MenuItem value={item.wix_id}>
                              {item.wix_id}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DialogField>
                  )}

                  {loc.WixMenuConfigId ? (
                    <DialogField className='w50'>
                      <FieldInput
                        disabled
                        label='Menu Config'
                        value={loc.WixMenuConfigId}
                      />
                    </DialogField>
                  ) : (
                    <DialogField className='w50 Say2EatSelect'>
                      <FieldLabel>Menu Config</FieldLabel>
                      <FormControl>
                        <Select
                          inputProps={{'aria-label': 'value'}}
                          onChange={formik.handleChange}
                          value={loc.WixMenuConfigId}
                          name={`Locations[${index}].WixMenuConfigId`}
                        >
                          {menuConfig.map((item) => (
                            <MenuItem value={item.wix_id}>
                              {item.wix_id}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </DialogField>
                  )}
                </DialogFields>
                {loc?.CopyMenuUrl && (
                  <FlexContainer
                    justifyContent='space-between'
                    alignItems='center'
                    className='marginTopXs marginBottomXs'
                  >
                    <FlexItem>
                      <Body1>Click to download user&apos;s uploaded menu</Body1>
                    </FlexItem>
                    <FlexItem>
                      <ButtonPrimary
                        className='ButtonMd'
                        onClick={() => window.open(loc?.CopyMenuUrl)}
                      >
                        <span>Download</span>
                      </ButtonPrimary>
                    </FlexItem>
                  </FlexContainer>
                )}

                {loc?.CopyImagesUrl && (
                  <FlexContainer
                    justifyContent='space-between'
                    alignItems='center'
                    className='marginTopXs marginBottomXs'
                  >
                    <FlexItem>
                      <Body1>
                        Click to download user&apos;s uploaded menu images
                      </Body1>
                    </FlexItem>
                    <FlexItem>
                      <ButtonPrimary
                        className='ButtonMd'
                        onClick={() => window.open(loc?.CopyImagesUrl)}
                      >
                        <span>Download</span>
                      </ButtonPrimary>
                    </FlexItem>
                  </FlexContainer>
                )}
                {/* TODO hotfix GROW-278 */}
                {/* <TestOrderPanel loc={loc} /> */}
                <FlexContainer justifyContent='space-between'>
                  <FlexItem>
                    <DialogField>
                      {loc.status !== 'ACTIVE' && (
                        <ButtonPrimary
                          onClick={() => {
                            dispatch(ACTIVATE_LOCATION(locationId));
                          }}
                        >
                          <span>Activate</span>
                        </ButtonPrimary>
                      )}
                    </DialogField>
                  </FlexItem>
                  <FlexItem>
                    <DialogField>
                      <ButtonOutline
                        className='ButtonMin'
                        onClick={() => {
                          dispatch(
                            CHANGE_LOCATION(formik.values.Locations[index])
                          );
                        }}
                      >
                        <span>Save</span>
                      </ButtonOutline>
                    </DialogField>
                  </FlexItem>
                </FlexContainer>
              </>
            );
          })}
        </DialogBody>
      </DialogContainer>
      <InfoModal
        isOpen={warningModalOpen}
        onOk={() => setWarningModalOpen(false)}
        message='Attention! Refunds and Sales Summary Reports will be unavailable as this location has a Stripe standard account connected.'
      />
    </Dialog>
  );
}

EditActiveMerchant.propTypes = {
  onClose: PropTypes.func.isRequired
};
