import {toast} from 'react-toastify';
import {put, select, takeEvery} from 'redux-saga/effects';
import {
  CREATE_FEATURE,
  GET_SERVICE_MENU_ITEMS,
  GET_SERVICE_MENU_ITEMS_FAIL,
  GET_SERVICE_MENU_ITEMS_SUCCESS,
  SET_MODAL_LOADING,
  UPDATE_LOCATION_SERVICE_FEATURE,
  UPDATE_SERVICE_FEATURE,
  UPDATE_SERVICE_FEATURE_AVAILABILITY,
  UPDATE_SERVICE_FEATURE_AVAILABILITY_SUCCESS,
  UPDATE_SERVICE_FEATURE_SUCCESS
} from './action';
import getServiceMenuItemsApi from '../../helpers/services/api/appSettings';
import {
  createFeatureApi,
  updateLocationServiceFeatureApi,
  updateServiceFeatureApi,
  updateServiceFeatureAvailabilityApi
} from '../../helpers/services/api/featureManagement';
import {GET_ACTIVE_MERCHANTS} from '../merchants/action';
import sendGtmEvent from '../../helpers/services/utils/gtm';

const getMerchantsState = ({merchantsReducer}) => merchantsReducer;

export function* getServiceMenuItems() {
  try {
    const data = yield getServiceMenuItemsApi();

    if (data && data?.status === 200) {
      yield put(GET_SERVICE_MENU_ITEMS_SUCCESS(data?.data));
    } else {
      yield put(GET_SERVICE_MENU_ITEMS_FAIL());
    }
  } catch (error) {
    yield put(GET_SERVICE_MENU_ITEMS_FAIL());
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

export function* updateServiceFeature({payload}) {
  try {
    yield put(SET_MODAL_LOADING(true));
    const data = yield updateServiceFeatureApi(payload);

    if (data) {
      yield put(UPDATE_SERVICE_FEATURE_SUCCESS(payload));
      toast.success('This feature has been updated!');
      yield put(SET_MODAL_LOADING(false));
      payload?.callBack();
    }
  } catch (error) {
    yield put(SET_MODAL_LOADING(false));
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

export function* updateServiceFeatureAvailability({payload}) {
  try {
    yield put(SET_MODAL_LOADING(true));
    const data = yield updateServiceFeatureAvailabilityApi(payload);

    if (data) {
      yield put(UPDATE_SERVICE_FEATURE_AVAILABILITY_SUCCESS(payload));
      toast.success('This feature has been updated!');
      yield put(SET_MODAL_LOADING(false));
      payload?.callBack();
    }
  } catch (error) {
    yield put(SET_MODAL_LOADING(false));
    toast.error(`Error occurred - please try again [${error}]`);
  }
}

export function* updateLocationServiceFeature({payload}) {
  try {
    const {
      paging: {page},
      search,
      sort
    } = yield select(getMerchantsState);

    yield put(SET_MODAL_LOADING(true));

    const {data} = yield updateLocationServiceFeatureApi(payload.id, {
      AllowedServiceFeatures: payload.data
    });

    if (data) {
      yield put(GET_ACTIVE_MERCHANTS({page, search, sort}));
      yield put(SET_MODAL_LOADING(false));
      toast.success('Location features have been updated!');
      if (data.addedAllowedServiceFeatures.includes('show_marketing_results')) {
        sendGtmEvent('switch-on-marketing-paying-user-flag', {
          location_name: payload.locationName,
          location_id: payload.id
        });
      }
    }
  } catch (error) {
    yield put(SET_MODAL_LOADING(false));
  }
}

function* createNewFeature({payload}) {
  try {
    yield put(SET_MODAL_LOADING(true));
    const {data} = yield createFeatureApi(payload);
    if (data) {
      yield put(SET_MODAL_LOADING(false));
      yield put(GET_SERVICE_MENU_ITEMS_SUCCESS(data));
    }
  } catch (e) {
    yield put(SET_MODAL_LOADING(false));
  }
}

function* featureManagementSaga() {
  yield takeEvery(GET_SERVICE_MENU_ITEMS, getServiceMenuItems);
  yield takeEvery(UPDATE_SERVICE_FEATURE, updateServiceFeature);
  yield takeEvery(
    UPDATE_LOCATION_SERVICE_FEATURE,
    updateLocationServiceFeature
  );
  yield takeEvery(
    UPDATE_SERVICE_FEATURE_AVAILABILITY,
    updateServiceFeatureAvailability
  );
  yield takeEvery(CREATE_FEATURE, createNewFeature);
}

export default featureManagementSaga;
