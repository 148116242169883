import React, {useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import PropTypes from 'prop-types';
import {
  Logo,
  SidebarContainer,
  SidebarHolder,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarOverlay,
  SidebarProfile,
  SidebarProfileInfo,
  SidebarProfileLogoutButton,
  SidebarProfileName,
  SidebarProfilePicture,
  SidebarProfilePosition,
  SidebarTitle,
  SidebarWrapper
} from './styles';
import {LOGOUT_USER} from '../../../../../store/global/auth/action';
import {
  HeaderLside,
  ManagersHeader,
  ManagersHeaderBurger
} from '../../../../../components/layout/nav/styles';
import SauceLogo from '../../../../../assets/logo-sauce.svg';
import Navbar from '../../Header/Navbar';

const Sidebar = ({showSidebar, setShowSidebar}) => {
  const dispatch = useDispatch();
  const store = useSelector((state) => state);
  const {AllowedFeatures} = useSelector((state) => state.user.userData);

  const email = store?.user?.userData?.email;
  const emailName = email.substring(0, email.lastIndexOf('@'));
  const emailDomain = email.substring(email.lastIndexOf('@'));

  return (
    <>
      {/* <ManagersHeader>
        <HeaderLside>
          <ManagersHeaderBurger
            className={showSidebar ? 'isOpen' : ''}
            onClick={() => setShowSidebar(!showSidebar)}
          >
            <span />
          </ManagersHeaderBurger>
        </HeaderLside>
      </ManagersHeader> */}
      <SidebarWrapper className={showSidebar ? 'isOpen' : ''}>
        <SidebarContainer>
          <Logo>
            <img src={SauceLogo} alt='logo' />
          </Logo>
          <SidebarHolder>
            <SidebarMenu>
              {AllowedFeatures.includes('merchants') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/merchants'
                  >
                    Merchants
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('menu_queue') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/menu-queue'
                  >
                    Menu Queue
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('users') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/users'
                  >
                    Users
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('user_management') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/permission-management'
                  >
                    Permission Management
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('feature_management') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/feature-management'
                  >
                    Feature Management
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('real_time_board') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/real-time-board'
                  >
                    Real Time Dispatch
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('real_time_board_v2') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/real-time-board-v2'
                  >
                    Real Time Dispatch V2
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures.includes('find_delivery') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/find-delivery'
                  >
                    Find Delivery
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('insights_control') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/insights-control'
                  >
                    Insights (internal)
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('refunds_calculator') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/refunds-calculator'
                  >
                    Refunds Calculator
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
              {AllowedFeatures?.includes('phone_ordering') && (
                <SidebarMenuItem>
                  <SidebarMenuButton
                    activeClassName='isActive'
                    to='/managers/phone-ordering'
                  >
                    Phone Ordering
                  </SidebarMenuButton>
                </SidebarMenuItem>
              )}
            </SidebarMenu>
          </SidebarHolder>
        </SidebarContainer>
        {store.user.userData && Object.keys(store.user.userData).length !== 0 && (
          <SidebarProfile>
            <SidebarProfilePicture>
              {store.user.userData.email.slice(0, 1).toUpperCase()}
            </SidebarProfilePicture>
            {/* <SidebarProfilePhoto src={UserPhoto} alt="" /> */}
            <SidebarProfileInfo>
              <SidebarProfileName>
                <span>{emailName}</span>
                <span>{emailDomain}</span>
              </SidebarProfileName>
              <SidebarProfilePosition>
                {store.user.userData.MerchantOriginName}
              </SidebarProfilePosition>
              <SidebarProfileLogoutButton
                onClick={() => {
                  dispatch(LOGOUT_USER());
                  localStorage.removeItem('access_token');
                }}
                href={`${process.env.REACT_APP_OLD_DASHBOARD_URL}/logout`}
              />
            </SidebarProfileInfo>
          </SidebarProfile>
        )}
        <SidebarOverlay
          onClick={() => setShowSidebar(!showSidebar)}
          className='SidebarOverlay'
        />
      </SidebarWrapper>
    </>
  );
};

Sidebar.propTypes = {
  showSidebar: PropTypes.bool.isRequired,
  setShowSidebar: PropTypes.func.isRequired
};

export default Sidebar;
