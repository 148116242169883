import {createAction} from 'redux-act';

export const GET_ORDERS = createAction('orders/GET_ORDERS');
export const SET_NEXT_PAGE = createAction('orders/SET_NEXT_PAGE');
export const ORDERS_UNMOUNT = createAction('orders/ORDERS_UNMOUNT');
export const SET_FILTER = createAction('orders/SET_FILTER');
export const SET_SORTING = createAction('orders/SET_SORTING');
export const SET_SEARCH = createAction('orders/SET_SEARCH');
export const CLEAR_ORDERS = createAction('orders/CLEAR_ORDERS');
export const CHANGE_REFUNDS_HISTORY = createAction(
  'orders/CHANGE_REFUNDS_HISTORY'
);
export const CHANGE_STATUS_HISTORY = createAction(
  'orders/CHANGE_STATUS_HISTORY'
);
export const CHANGE_STATUS_HISTORY_SUCCESS = createAction(
  'orders/CHANGE_STATUS_HISTORY_SUCCESS'
);
export const REFRESH_ORDERS = createAction('orders/REFRESH_ORDERS');
export const SET_ORDERS = createAction('orders/SET_ORDERS');
export const SET_ORDERS_EMPTY = createAction('orders/SET_ORDERS_EMPTY');
export const CHANGE_ORDER_INFO = createAction('orders/CHANGE_ORDER_INFO');
export const GET_ORDER = createAction('orders/GET_ORDER');
export const GET_ORDER_SUCCESS = createAction('orders/GET_ORDER_SUCCESS');
export const GET_LOCATION_ID_DATA = createAction('orders/GET_LOCATION_ID_DATA');
export const GET_LOCATION_ID_DATA_SUCCESS = createAction(
  'orders/GET_LOCATION_ID_DATA_SUCCESS'
);
export const GET_CLOUD_PRINT_CONFIGURATIONS = createAction(
  'orders/GET_CLOUD_PRINT_CONFIGURATIONS'
);
export const GET_CLOUD_PRINT_CONFIGURATIONS_SUCCESS = createAction(
  'orders/GET_CLOUD_PRINT_CONFIGURATIONS'
);
export const UPDATE_CLOUD_PRINT_CONFIGURATIONS = createAction(
  'orders/UPDATE_CLOUD_PRINT_CONFIGURATIONS'
);
export const CLEAR_CLOUD_PRINT_CONFIGURATIONS = createAction(
  'orders/CLEAR_CLOUD_PRINT_CONFIGURATIONS'
);
export const PATCH_LOCATION_ACTION = createAction('orders/PATCH_LOCATION');
export const SEND_TEST_ORDER = createAction('orders/SEND_TEST_ORDER');
export const SEND_TEST_ORDER_SUCCESS = createAction(
  'orders/SEND_TEST_ORDER_SUCCESS'
);
export const CLEAR_FILTERS = createAction('orders/CLEAR_FILTERS');
export const OPEN_STATUS_CHANGE_MODAL = createAction(
  'globalNotifications/OPEN_STATUS_CHANGE_MODAL'
);
export const TURN_OFF_COMPLETED_NOTIFICATION = createAction(
  'globalNotifications/TURN_OFF_COMPLETED_NOTIFICATION'
);

export const OPEN_CANCELLATION_CONFIRMATION_MODAL = createAction(
  'orders/OPEN_CANCELLATION_CONFIRMATION_MODAL'
);
