import {put, takeEvery} from 'redux-saga/effects';
import {
  GET_MERCHANTS,
  GET_MERCHANTS_SUCCESS
} from 'store/global/connectingStream/action';
import {getMerchantsApi} from 'helpers/services/api/connectingStreamApi';

function* getMerchants({payload}) {
  try {
    const {data} = yield getMerchantsApi(payload);
    if (data) {
      yield put(GET_MERCHANTS_SUCCESS(data));
    }
  } catch (e) {
    yield put(GET_MERCHANTS_SUCCESS([]));
    console.error('SAGA ERROR', e);
  }
}

function* connectingStreamSaga() {
  yield takeEvery(GET_MERCHANTS, getMerchants);
}

export default connectingStreamSaga;
