import {takeEvery, put} from 'redux-saga/effects';
import {toast} from 'react-toastify';
import {SEND_REQUEST, SET_LOADING} from 'store/flyers/action';
import sendFlyersRequestApi from 'helpers/services/api/flyers';

function* sendFlyersRequest({payload}) {
  try {
    yield put(SET_LOADING(true));

    const data = yield sendFlyersRequestApi(payload);
    if (data) {
      toast.success('Your customer success agent will contact you shortly');
    }
    yield put(SET_LOADING(false));
  } catch (error) {
    console.log('SAGA ERROR', error);
    yield put(SET_LOADING(false));
    toast.error('Something went wrong');
  }
}

function* flyersSaga() {
  yield takeEvery(SEND_REQUEST, sendFlyersRequest);
}

export default flyersSaga;
