import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import cn from 'classnames';
import {NewLineBlock, OrderEta} from '../../OrdersList/styles';
import {
  ORDER,
  ORDER_STATUS,
  ORDER_TYPE,
  ORDER_TYPES
} from '../../../../../helpers/constants/orders';
import {FlexContainer, FlexItem} from '../../../../../components/layout';
import UberEats from '../../../../../assets/uber-eats.svg';
import SauceDelivery from '../../../../../assets/sauce-delivery.png';
import {groupDigital} from '../../../../../helpers/functionUtils/groupByDigital';
import StatusButton from '../../OrdersList/components/StatusButton';
import IconPrint from '../../../../../assets/IconPrint';
import TableComponent from '../../../../../components/TableComponent';
import {phoneFormatForView} from '../../../../../helpers/functionUtils/helpFunctions';
import {
  IconButtonPrinter,
  OrdersTableWrapper,
  SavingsIconButton
} from './styles';
import {
  checkIsFullNotToSauceRefundOrder,
  checkIsFullRefundOrder,
  checkIsFutureOrder,
  checkIsRefundOrder
} from '../../../../../helpers/functionUtils/ordersUtils';
import IconPickUpDefault from '../../../../../assets/icon-pickup-default.svg';
import IconCurbsidePickup from '../../../../../assets/icon-curbside-pickup.svg';
import IconDinein from '../../../../../assets/icon-dinein.svg';
import IconRestaurantDelivery from '../../../../../assets/icon-restaurant-delivery.svg';
import IconSauceDelivery from '../../../../../assets/icon-sauce-delivery.svg';
import {ButtonOutline} from '../../../../../components/buttons';
import {TableText} from '../../../../../components/TableComponent/styles';
import {FEATURE_RESTRICTION_IDS} from '../../../../../helpers/constants/restrictions';
import RestrictedArea from '../../../../../components/layout/RestrictedArea';

const getFieldsConfig = (
  onStatusChange,
  onOpenDetailsClick,
  onPrintClick,
  isNetworkOnline,
  changeStatusLoading,
  onDeliverWithUsClick,
  showSavingPigIcon,
  showDeliverWithUsButton
) => [
  {
    title: 'ETA',
    sortable: true,
    onCellClick: onOpenDetailsClick,
    fieldName: 'eta_time_for_merchant',
    renderer: (data) => (
      <OrderEta>
        {checkIsFutureOrder(data) &&
          !checkIsFullNotToSauceRefundOrder(data) && (
            <span className='IconFutureOrder' />
          )}
        {(checkIsFullNotToSauceRefundOrder(data) ||
          (checkIsRefundOrder(data) && !checkIsFutureOrder(data))) && (
          <span className='IconRefundOrder' />
        )}
        {moment(data[ORDER.DATE]).utcOffset(0).format('MMMM D, h:mm A')}
      </OrderEta>
    )
  },
  {
    title: 'Customer',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <FlexContainer className='TableCenter'>
        <NewLineBlock className='wordWrap'>
          {data[ORDER.USER_NAME]}
        </NewLineBlock>
        <NewLineBlock>
          <a
            href={`tel:${data[ORDER.USER_PHONE]}`}
            alt='customer-phone-number'
            onClick={(e) => e.stopPropagation()}
          >
            {phoneFormatForView(data[ORDER.USER_PHONE])}
          </a>
        </NewLineBlock>
      </FlexContainer>
    )
  },
  /* {
    title: 'Order channel',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <FlexContainer className='align-center orderProvider'>
        <FlexItem className='marginRightXs orderIconContainer'>
          {data[ORDER.PLATFORM]?.toLowerCase().includes('uber') ? (
            <img src={UberEats} alt='logo' />
          ) : (
            <img src={SauceDelivery} alt='logo' />
          )}
        </FlexItem>
        <FlexItem>
          {data[ORDER.PLATFORM]?.toLowerCase().includes('uber')
            ? 'UberEats'
            : 'Sauce'}
        </FlexItem>
      </FlexContainer>
    )
  }, */
  {
    title: 'Order type',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => {
      return (
        <FlexContainer className='align-center orderProvider'>
          <FlexItem flex={1} className='marginRightXs orderIconContainer'>
            {(() => {
              if (data?.isCurbside) {
                return <img src={IconCurbsidePickup} alt='logo' />;
              }
              switch (data[ORDER.ORDER_TYPE]) {
                case 'DineIn':
                  return <img src={IconDinein} alt='logo' />;
                case 'SauceDispatch':
                  return <img src={IconSauceDelivery} alt='logo' />;
                case 'UberEatsDelivery':
                  return <img src={UberEats} alt='logo' />;
                case 'UberPickUp':
                  return <img src={UberEats} alt='logo' />;
                case 'Delivery':
                  return <img src={IconRestaurantDelivery} alt='logo' />;
                default:
                  return <img src={IconPickUpDefault} alt='logo' />;
              }
            })()}
          </FlexItem>
          <FlexItem className='marginRight'>
            <TableText>
              {data?.isCurbside
                ? ORDER_TYPES[ORDER_TYPE.CURBSIDE_PICKUP]
                : ORDER_TYPES[data[ORDER.ORDER_TYPE]]}
            </TableText>
          </FlexItem>
          <RestrictedArea
            restrictions={[]}
            features={[FEATURE_RESTRICTION_IDS.UBER_EATS]}
            extraAccessLogic={() =>
              data[ORDER.DELIVERY_PROVIDER] === 'Uber Eats' &&
              showSavingPigIcon &&
              data[ORDER.ORDER_TYPE] === 'SauceDispatch'
            }
          >
            <FlexItem className='SavingInfo marginLeftXs'>
              <SavingsIconButton>
                <div className='DropInfo DropInfoUp DropInfoCenter'>
                  You save money on every UberEats order that Sauce delivers.
                </div>
              </SavingsIconButton>
            </FlexItem>
          </RestrictedArea>
        </FlexContainer>
      );
    }
  },
  {
    onCellClick: () => {},
    renderer(data = {}) {
      if (
        data[ORDER.DELIVERY_PROVIDER] === 'Uber Eats' &&
        showDeliverWithUsButton
      ) {
        return (
          <RestrictedArea
            restrictions={[]}
            features={[FEATURE_RESTRICTION_IDS.UBER_EATS]}
          >
            <FlexItem className='OrderTdDelivery'>
              <ButtonOutline
                className='ButtonSm Delivery fw700'
                onClick={onDeliverWithUsClick}
              >
                Deliver with us
              </ButtonOutline>
            </FlexItem>
          </RestrictedArea>
        );
      }
      return false;
    }
  },
  {
    title: 'Amount',
    onCellClick: onOpenDetailsClick,
    renderer: (data) => (
      <div>{`$${groupDigital(Number(data[ORDER.AMOUNT]).toFixed(2))} ${
        data[ORDER.PAYMENT_TYPE] || ''
      }`}</div>
    )
  },
  {
    title: 'Status',
    fieldName: 'OrderStatus',
    renderer: (data) => {
      return (
        <div
          style={
            !isNetworkOnline ||
            changeStatusLoading === data[ORDER.ID] ||
            data[ORDER.ORDER_STATUS] === ORDER_STATUS.CANCELLED
              ? {opacity: '0.5', pointerEvents: 'none'}
              : null
          }
        >
          <StatusButton
            order={data}
            onStatusChange={(status) => {
              onStatusChange(
                data[ORDER.ID],
                status,
                data[ORDER.ORDER_STATUS],
                data[ORDER.DELIVERY_PROVIDER]
              );
            }}
          />
        </div>
      );
    }
  },
  {
    renderer: (item) => (
      <FlexContainer className='justify-end'>
        <IconButtonPrinter
          data-gtm='printButton'
          onClick={() => onPrintClick(item)}
        >
          <IconPrint data-gtm='printButton' />
        </IconButtonPrinter>
      </FlexContainer>
    )
  }
];

const OrdersTable = ({
  onStatusChange,
  openDetails,
  printOrder,
  orders,
  loading,
  onSort,
  orderBy,
  orderDirection,
  isNetworkOnline,
  changeStatusLoading,
  onDeliverWithUsClick,
  showSavingPigIcon,
  showDeliverWithUsButton,
  isOrdersTable
}) => {
  return (
    <OrdersTableWrapper
      className={cn('OrdersTableWrapper', {
        NoOrders: !orders.length && isOrdersTable
      })}
    >
      <TableComponent
        fields={getFieldsConfig(
          onStatusChange,
          openDetails,
          printOrder,
          isNetworkOnline,
          changeStatusLoading,
          onDeliverWithUsClick,
          showSavingPigIcon,
          showDeliverWithUsButton
        )}
        data={orders}
        loading={loading}
        rowKeyPath={ORDER.ID}
        onSort={onSort}
        orderBy={orderBy}
        orderDirection={orderDirection}
        isOrdersTable
      />
    </OrdersTableWrapper>
  );
};

OrdersTable.propTypes = {
  onStatusChange: PropTypes.func.isRequired,
  openDetails: PropTypes.func.isRequired,
  printOrder: PropTypes.func.isRequired,
  orders: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  isNetworkOnline: PropTypes.bool.isRequired,
  onSort: PropTypes.func,
  orderBy: PropTypes.string,
  orderDirection: PropTypes.string,
  changeStatusLoading: PropTypes.string.isRequired,
  onDeliverWithUsClick: PropTypes.func.isRequired,
  showSavingPigIcon: PropTypes.bool.isRequired,
  showDeliverWithUsButton: PropTypes.bool.isRequired,
  isOrdersTable: PropTypes.bool
};

OrdersTable.defaultProps = {
  onSort: () => {},
  orderBy: '',
  orderDirection: '',
  isOrdersTable: false
};

export default OrdersTable;
