import styled from 'styled-components';

const DialogContainer = styled.div`
  overflow: hidden;

  &.DialogXs {
    width: 360px;
    padding: 54px 20px 35px 20px;
    @media (max-width: 1000px) {
      padding: 50px 20px;
    }
    @media (max-width: 767px) {
      padding: 40px 20px;
    }
    @media (max-width: 450px) {
      width: 100%;
      padding: 40px 10px;
    }
  }

  &.DialogSm3 {
    width: 470px;
    padding: 32px 46px 64px 46px;
    @media (max-width: 1000px) {
      padding: 40px 34px;
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
    }
    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &.DialogSm2 {
    width: 510px;
    padding: 40px 34px 45px 34px;
    @media (max-width: 1000px) {
      padding: 40px 34px;
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
    }
    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &.DialogSm {
    width: 540px;
    padding: 54px 50px 65px 50px;
    &.ConnectingStreamPopup {
      padding-bottom: 30px;
    }
    @media (max-width: 1000px) {
      padding: 50px 40px;
      &.ConnectingStreamPopup {
        padding-bottom: 30px;
      }
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
      &.ConnectingStreamPopup {
        padding-bottom: 30px;
      }
    }
    @media (max-width: 640px) {
      width: 100%;
    }
  }

  &.DialogMd {
    width: 640px;
    padding: 54px 50px 65px 50px;
    &.ConnectingStreamPopup {
      padding-bottom: 30px;
    }
    @media (max-width: 1000px) {
      padding: 50px 40px;
      &.ConnectingStreamPopup {
        padding-bottom: 30px;
      }
    }
    @media (max-width: 767px) {
      width: 500px;
      padding: 40px 25px;
      &.ConnectingStreamPopup {
        padding-bottom: 30px;
      }
    }
    @media (max-width: 600px) {
      width: 440px;
    }
    @media (max-width: 520px) {
      width: 100%;
    }
  }

  &.DialogMdPadding {
    width: 660px;
    padding: 50px 42px 64px 48px;

    @media (max-width: 1000px) {
      padding: 50px 40px;
    }
    @media (max-width: 767px) {
      width: 500px;
      padding: 40px 25px;
    }
    @media (max-width: 600px) {
      width: 440px;
    }
    @media (max-width: 520px) {
      width: 100%;
    }
  }

  &.DialogM {
    width: 720px;
    padding: 54px 50px 65px 50px;
    @media (max-width: 1000px) {
      padding: 50px 40px;
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
      width: 600px;
    }
    @media (max-width: 650px) {
      width: 500px;
    }
    @media (max-width: 550px) {
      width: 400px;
    }
    @media (max-width: 450px) {
      width: 100%;
    }
  }

  &.DialogWithSidebar {
    width: 1185px;
    display: flex;
    align-items: flex-start;

    .DialogHolder {
      flex: 1 1 auto;
      padding: 50px;
      @media (max-width: 1000px) {
        padding: 50px 40px;
      }
    }

    @media (max-width: 1250px) {
      width: 720px;
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
    }
    @media (max-width: 740px) {
      width: 100%;
    }
  }

  &.DialogLg {
    width: 980px;
    padding: 54px 50px 65px 50px;
    @media (max-width: 1200px) {
      max-width: 980px;
      width: 100%;
    }
    @media (max-width: 1000px) {
      padding: 50px 40px;
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
    }
  }

  &.DialogXl {
    width: 1100px;
    padding: 54px 50px 65px 50px;
    @media (max-width: 1200px) {
      max-width: 980px;
      width: 100%;
    }
    @media (max-width: 1000px) {
      padding: 50px 40px;
    }
    @media (max-width: 767px) {
      padding: 40px 25px;
    }
  }
  &.DialogVideo {
    width: 1100px;
    padding: 48px;
    .DialogClose {
      top: 15px;
      right: 15px;
    }
    @media (max-width: 1200px) {
      max-width: 980px;
      width: 100%;
      padding: 42px;
      .DialogClose {
        top: 12px;
        right: 11px;
      }
    }
    @media (max-width: 1000px) {
      padding: 38px;
      .DialogClose {
        top: 10px;
        right: 9px;
      }
    }
    @media (max-width: 767px) {
      padding: 35px;
      .DialogClose {
        top: 9px;
        right: 8px;
      }
    }
    @media (max-width: 599px) {
      padding: 30px;
      .DialogClose {
        top: 6px;
        right: 5px;
      }
    }
  }

  &.DialogDark {
    background: #232d3a;

    * {
      color: #fff;
    }
  }

  &.ConfirmModal {
    .ConfirmModal-Title {
      text-align: center;
      max-width: 250px;
      margin: 0 auto 24px auto;
      font-size: 16px;
      color: #2f3d48;

      &.widthAuto {
        max-width: 100%;
      }
    }

    .ConfirmModal-BtnPanel {
      display: flex;
      justify-content: center;
      margin-top: 16px;
    }
  }

  &.Overflow {
    overflow: auto;
  }

  .DialogVideoContainer {
    position: relative;

    &::before {
      position: absolute;
      pointer-events: none;
      top: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 100%;
      z-index: 1;
      background-color: rgb(0, 0, 0, 0.5);
      display: none;
    }
    .ButtonCloseVideo {
      display: none;
    }

    &.show {
      .ButtonCloseVideo {
        display: block;
      }
      &::before {
        display: block;
      }
      .w-bottom-bar {
        z-index: 2;
      }
    }
  }
  &.InsightsPerformanceSettingsModal {
    width: 463px;
    padding: 53px 53px 66px 53px;

    @media (max-width: 640px) {
      padding: 53px 16px 66px 16px;
      width: 100%;
    }
  }
  &.InsightsControlEdit {
    max-height: 800px;
  }
`;

export default DialogContainer;
